import io from "socket.io-client";
import { getBackendUrl } from "../config";
import toastError from "../errors/toastError";
import { toastErrorSocket } from "../errors/toastErrorSocket";



function openSocket() {

    let ERROR_CONT = 0;

    try {
        const socket = io(getBackendUrl(), { transports: ["websocket", "polling"] })

        socket.on("error", (err) => {
            console.log('error socket:', err.message, JSON.stringify(err));
            toastErrorSocket("Erro Socket. Verifique Internet e log Console Navegador!");
        });

        socket.on("connect_error", (err) => {
            console.log('connect_error socket:', err.message, JSON.stringify(err), ', ERROR_CONT:', ERROR_CONT);

            if (ERROR_CONT !== 0) {
                toastErrorSocket("Erro Conexão Socket. Verifique Internet e log console. Atualize Pagina!");

            } else {

                console.log('connect_error socket ERROR_CONT:', ERROR_CONT);
                socket.io.opts.transports = ["polling", "websocket"];
                ERROR_CONT++;
            }

        });

        socket.on("reconnect_failed", (err) => {
            console.log('reconnect_failed socket:', err.message, JSON.stringify(err));
            toastErrorSocket("Erro Falha Reconnect Socket. Verifique Internet e log Console!");
        });

        socket.on("reconnect_error", (err) => {
            console.log('reconnect_failed socket:', err.message, JSON.stringify(err));
            toastErrorSocket("Erro Falha Reconnect Socket. Verifique Internet e log Console!");
        });

        socket.on("reconnect_attempt", (err) => {
            console.log('reconnect_attempt:', err.message, JSON.stringify(err));
            toastErrorSocket("Erro Tentativas Reconnect Socket. Verifique Internet e log Console!");
        });

        socket.on("reconnect", (err) => {
            console.log('reconnect socket:', err.message, JSON.stringify(err));
            toastErrorSocket("Socket Reconnect. Houve Falha Conexão Socket, mas se reconectou!");
        });

        socket.on("ping", (err) => {
            console.log('ping socket:', err.message, JSON.stringify(err));
            toastErrorSocket("Erro Checking ping Socket. Verifique Internet e log Console!");
        });

        socket.on("connect", () => {

            if (ERROR_CONT === 0) {
                ERROR_CONT++;
            }
            //console.log(`connect ${socket.id}, CONT_CONNECT: ${ERROR_CONT}`); // prints { token: "abcd" }
        });





        return socket;
    } catch (err) {
        toastError(err);
    }

}

export default openSocket;