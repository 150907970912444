import React, { useState, useEffect } from "react";
import toastError from "../../errors/toastError";
import {
    TextField,
    CircularProgress,
    Select,
    MenuItem,
    Box,
    FormControl,
    InputLabel,
    Grid,
    Divider as MuiDivider
} from '@material-ui/core';
import { GetColor } from "./ColorCategory"
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Field } from "formik";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({

    selectObrigatorio: {
        "&:after": {
            borderBottomColor: "#2576d2",
        },
        "& .MuiSvgIcon-root": {
            color: "#2576d2",
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2576d2'
        },

    },
    textFieldObrigatorio: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#2576d2',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#2576d2',
            },
        },
    },
    selectOpcional: {
        "&:after": {
            borderBottomColor: "#DDA0DD",
        },
        "& .MuiSvgIcon-root": {
            color: "#DDA0DD",
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DDA0DD'
        },

    },
    textFieldOpcional: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#DDA0DD',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#DDA0DD',
            },
        },
    }

}));

const initial = () => {

    return {};

};

const HeaderTicket = (props) => {

    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchParam, setSearchParam] = useState("");
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);


    const initialParamsLocalStorage = () => {

        //console.log('props', props);
        if (!props.opcional) {

            const ini = initial();

            props.setUserCreateId(ini?.userCreateId);
            props.setUserId(ini?.userId);
            props.setCategoryId(ini?.categoryId);
            props.setStatusId(ini?.statusId);
            props.setPriorityId(ini?.priorityId);

        }

    };

    useEffect(() => {

        api.get("/users/").then((response) => {
            console.log('response', response);
            setUsers(response.data.users);

        }).catch((error) => {
            console.log('error', error);
            toastError(error);
        });
      }, []);


    useEffect(() => {

        api.get("rusers").then((response) => {

            //console.log('data', response.data);
            //setUsers(response.data.users);
            setCategories(response.data.categorias);

            initialParamsLocalStorage();
        }).catch((error) => {
            console.log('error', error);
            toastError(error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let isMounted = true;

        if (searchParam.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {

                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam },
                    });

                    if (isMounted) {
                        setOptions(data.contacts);
                        setLoading(false);
                    }
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }

                return () => {
                    isMounted = false;
                }
            };

            fetchContacts();
        }, 500);
        return () => {
            clearTimeout(delayDebounceFn);
            isMounted = false;
        }
    }, [searchParam]);

    const findColor = (catId) => {
        if (categories.length > 0) {
            for (const key in categories) {
                if (Object.hasOwnProperty.call(categories, key)) {
                    const element = categories[key];

                    if (catId === element.id) {
                        return GetColor(element.color);
                    }
                }
            }
        } else {
            return '';
        }
    }

    const renderOptionLabel = option => {

        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const renderOption = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        }
    };

    const addOption = (filterOptions, params) => {

        const filtered = filter(filterOptions, params);

        return filtered;
    };
    const filter = createFilterOptions({
        trim: true,
    });

    const handleSelectOption = (e, newValue) => {

        const values = newValue.map((element) => {
            const { id, name, number } = element;
            return { id, name, number };
        });

        props.setSelectedContact(values);

    };

    const Dividers = ({ children, textAlign, ...props }) => (
        <Grid container alignItems="center" spacing={3} style={{ margin: "0 0 2px 2px", color: '#2576d2' }} {...props}>
            <Grid item xs={textAlign === 'left' ? 2 : true}>
                <MuiDivider />
            </Grid>
            <Grid item>{children}</Grid>
            <Grid item xs={textAlign === 'right' ? 2 : true}>
                <MuiDivider />
            </Grid>
        </Grid>
    );

    return (

        <>
            {
                props.divider && (
                    <Dividers textAlign="center">Dados Ticket (Obrigatório)</Dividers>
                )
            }

            <Box sx={{ display: 'flex', flexWrap: 'wrap', flex: 12 }}>

                <div style={{ minWidth: 200, flex: 3, marginRight: 10 }}>

                    <Field
                        as={TextField}
                        label="Titulo do Ticket"
                        name="title"
                        variant="outlined"
                        margin="dense"
                        className={classes.textFieldObrigatorio}
                        fullWidth
                        error={props.touched.title && Boolean(props.errors.title)}
                        value={props.values.title || ''}

                    />
                    {props.errors.title && props.touched.title ? (
                        <div>{props.errors.title}</div>
                    ) : null}
                </div>

                <FormControl variant="outlined" margin="dense" style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
                    <InputLabel>Status do Ticket</InputLabel>
                    <Field
                        as={Select}
                        name="status"
                        label="Status do Ticket"
                        variant="outlined"
                        value={props.statusId || ''} // 3 - Aberto (Status)
                        onChange={(e) => props.setStatusId(e.target.value)}
                        id="status-selection"
                        className={classes.selectObrigatorio}
                        style={{ color: findColor(props.statusId) }}

                    >
                        {!props.opcional && (<MenuItem value={''}>&nbsp;</MenuItem>)}
                        {categories.length > 0 && categories.map((categoria) => (
                            categoria.tipo === 2 && (
                                <MenuItem

                                    key={categoria.id}
                                    value={categoria.id}
                                >{categoria.title}</MenuItem>
                            )
                        ))}
                    </Field>
                </FormControl>


                <Autocomplete
                    multiple
                    value={props.selectedContact || ''}
                    style={{ minWidth: 200, flex: 3, marginRight: 10 }}
                    options={options}
                    loading={loading}
                    clearOnBlur
                    freeSolo
                    clearOnEscape
                    getOptionLabel={renderOptionLabel}
                    renderOption={renderOption}
                    filterOptions={addOption}
                    onChange={(e, newValue) => handleSelectOption(e, newValue)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={`Clientes Ticket ${props.opcional || ''}`}
                            variant="outlined"
                            margin="dense"
                            onChange={e => setSearchParam(e.target.value)}
                            className={classes.textFieldObrigatorio}

                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />

                <FormControl variant="outlined" margin="dense" style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
                    <InputLabel>Prioridade</InputLabel>
                    <Field
                        as={Select}
                        name="priority"
                        label="Prioridade"
                        value={props.priorityId || ''} // 6 - Prioridade Normal
                        onChange={(e) => props.setPriorityId(e.target.value)}
                        variant="outlined"
                        id="priority-selection"
                        className={classes.selectObrigatorio}
                        style={{ color: findColor(props.priorityId) }}
                    >
                        {!props.opcional && (<MenuItem value={''}>&nbsp;</MenuItem>)}
                        {categories.length > 0 && categories.map((categoria) => (
                            categoria.tipo === 3 && (
                                <MenuItem

                                    key={categoria.id}
                                    value={categoria.id}
                                >{categoria.title}</MenuItem>
                            )
                        ))}

                    </Field>
                </FormControl>


                {!props.opcional && (
                    <FormControl variant="outlined" margin="dense" style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
                        <InputLabel>Usuário Criou Ticket {props.opcional}</InputLabel>
                        <Field
                            as={Select}
                            name="user"
                            label={`Usuário Criou Ticket ${props.opcional || ''}`}
                            value={props.userCreateId || ''}
                            onChange={(e) => props.setUserCreateId(e.target.value)}
                            className={classes.selectOpcional}
                        >
                            <MenuItem value={''}>&nbsp;</MenuItem>
                            {users.length > 0 && users.map((user) => (
                                <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                )}



                <FormControl variant="outlined" margin="dense" style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
                    <InputLabel>Usuário Executando Ticket {props.opcional}</InputLabel>
                    <Field
                        as={Select}
                        name="user"
                        label={`Usuário Executando Ticket ${props.opcional || ''}`}
                        value={props.userId || ''}
                        onChange={(e) => props.setUserId(e.target.value)}
                        className={classes.selectOpcional}
                    >
                        <MenuItem value={''}>&nbsp;</MenuItem>
                        {users.length > 0 && users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                        ))}
                    </Field>
                </FormControl>

                {props.opcional && (<FormControl variant="outlined" margin="dense" style={{ minWidth: 200, marginRight: 10 }}>
                    <Field
                        type="checkbox"
                        name="isBug"
                        as={FormControlLabel}
                        control={<Checkbox />}
                        label="É Bug ?"
                        className={classes.selectObrigatorio}
                    />
                </FormControl>)}

            </Box>

            {
                props.divider && (
                    <Dividers textAlign="center">Dados Mensagem {props.opcional}</Dividers>
                )
            }


            <Box sx={{ display: 'flex', flexWrap: 'wrap', flex: 12 }}>
                <Field
                    as={TextField}
                    label={`Titulo da Mensagem ${props.opcional || ''}`}
                    name="subtitle"
                    variant="outlined"
                    margin="dense"
                    style={{ minWidth: 200, flex: 4, marginRight: 10 }}
                    className={classes.textFieldOpcional}
                    value={props.values.subtitle || ''}
                />

                <FormControl variant="outlined" margin="dense" style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
                    <InputLabel>Categoria {props.opcional}</InputLabel>
                    <Field
                        as={Select}
                        name="category"
                        value={props.categoryId || ''}
                        onChange={(e) => props.setCategoryId(e.target.value)}
                        label={`Categoria ${props.opcional || ''}`}
                        className={classes.selectOpcional}
                        style={{ color: findColor(props.categoryId) }}
                    >
                        <MenuItem value={''}>&nbsp;</MenuItem>
                        {categories.length > 0 && categories.map((categoria) => (
                            categoria.tipo === 1 && (
                                <MenuItem

                                    key={categoria.id}
                                    value={categoria.id}
                                >{categoria.title}</MenuItem>
                            )
                        ))}
                    </Field>
                </FormControl>

                {
                    props.divider && (
                        <div style={{ maxWidth: 200, flex: 2, marginRight: 10 }}>
                            <Field
                                as={TextField}
                                label="Tempo"
                                name="time"
                                variant="outlined"
                                margin="dense"
                                error={props.touched.time && Boolean(props.errors.time)}
                                fullWidth
                                className={classes.textFieldOpcional}
                                value={props.values.time || ''}
                            />
                            {props.errors.time && props.touched.time ? (
                                <div>{props.errors.time}</div>
                            ) : null}
                        </div>
                    )
                }


            </Box>
        </>
    );
};

export default HeaderTicket;