import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
//import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
//import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import useWhatsApps from "../../hooks/useWhatsApps";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
		marginBottom: 20
	},
}));

/*
const filterOptions = createFilterOptions({
	trim: true,
});*/

const TransferTicketModal = ({ modalOpen, onClose, ticketid, ticketWhatsappId }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [queues, setQueues] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [loading, setLoading] = useState(false);
	//const [searchParam, setSearchParam] = useState("");
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState('');
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
	const classes = useStyles();
	const { findAll: findAllQueues } = useQueues();
	const { loadingWhatsapps, whatsApps } = useWhatsApps();

	const { user: loggedInUser } = useContext(AuthContext);

	useEffect(() => {

		let isMounted = true;
		findAllQueues().then((list) => {

			if (isMounted) {
				setAllQueues(list);
				setQueues(list);
			}
		});

		return () => { isMounted = false; }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/*
	useEffect(() => {

		let isMounted = true;

		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);

		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/users/", {
						params: { searchParam },
					});

					if(isMounted) {
						setOptions(data.users);
						setLoading(false);
					}
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchUsers();
		}, 500);

		return () => {
			isMounted = false;
			clearTimeout(delayDebounceFn);
		}
		
	}, [searchParam, modalOpen]);
	*/

	useEffect(() => {

		let isMounted = true;

		const fetchUsers = async () => {
			try {
				const { data } = await api.get("/users/", {
					params: '',
				});

				if (isMounted) {
					setOptions(data.users);
					setLoading(false);
				}
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		};
		fetchUsers();

		return () => {
			isMounted = false;
		}

	}, []);

	const handleClose = () => {
		onClose();
		//setSearchParam("");
		setSelectedUser(null);
	};

	const handleSaveTicket = async e => {

		e.preventDefault();

		if (selectedQueue || selectedUser) {

			if (!ticketid) return;
			setLoading(true);
			try {
				let data = {};

				if (selectedUser) {
					data.userId = selectedUser.id
				}

				if (selectedQueue && selectedQueue !== null) {
					data.queueId = selectedQueue
				}

				if (selectedQueue &&
					selectedQueue !== null &&
					selectedUser?.queues.findIndex(t => t.id === selectedQueue) > -1) {

					data.queueId = selectedQueue

					if (!selectedUser) {
						data.status = 'pending';
						data.userId = null;
					}
				} else {

					if (selectedUser?.queues[0])
						data.queueId = selectedUser?.queues[0].id
				}

				/*
				if (selectedWhatsapp) {
					data.whatsappId = selectedWhatsapp;
				}*/

				await api.put(`/tickets/${ticketid}`, data);

				setLoading(false);
				history.push(`/tickets/${ticketid}`);
				handleClose();
				toast.success("Transferido com Sucesso.");
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>

					{/*
					<Autocomplete
						style={{ width: 300, marginBottom: 20 }}
						getOptionLabel={option => `${option.name}`}
						onChange={(e, newValue) => {
							setSelectedUser(newValue);
							if (newValue != null && Array.isArray(newValue.queues)) {
								setQueues(newValue.queues);
							} else {
								setQueues(allQueues);
								setSelectedQueue('');
							}
						}}
						options={options}
						filterOptions={filterOptions}
						freeSolo
						autoHighlight
						noOptionsText={i18n.t("transferTicketModal.noOptions")}
						loading={loading}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("transferTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>*/}

					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>{i18n.t("transferTicketModal.title")}</InputLabel>
						<Select
							value={selectedUser || ''}
							onChange={(e) => {
								setSelectedUser(e?.target?.value);
								if (e?.target?.value != null && Array.isArray(e.target?.value?.queues)) {
									setQueues(e.target?.value?.queues);
								} else {
									setQueues(allQueues);
									setSelectedQueue('');
								}
							}}
							label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
						>
							<MenuItem value={null}>&nbsp;</MenuItem>
							{options.map((users) => (
								<MenuItem key={users.id} value={users}>{users.name}</MenuItem>
							))}
						</Select>
					</FormControl>


					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
						<Select
							value={selectedQueue}
							onChange={(e) => setSelectedQueue(e?.target?.value)}
							label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}

						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>



					<Can
						role={loggedInUser.profile}
						perform="ticket-options:transferWhatsapp"
						yes={() => (!loadingWhatsapps &&
							<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
								<InputLabel>{i18n.t("transferTicketModal.fieldConnectionLabel")}</InputLabel>
								<Select
									disabled
									value={selectedWhatsapp || ''}
									onChange={(e) => setSelectedWhatsapp(e?.target?.value)}
									label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
								>
									{whatsApps.map((whasapp) => (
										<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					/>

				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;
