import React from "react";
import '@lottiefiles/lottie-player/dist/tgs-player.js';


const ModalImageSticker = ({ imageUrl }) => {

	return (
        <tgs-player
            autoplay
            mode="normal"
            src={imageUrl}
            style={{width: '120px', height: '120px'}}
          
        >
        </tgs-player>
	);
};

export default ModalImageSticker;