import React, { useRef, useState, useCallback } from 'react'
import { MenuItem, Menu } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';



const MoreMenuItem = ({ label, children, id, MenuProps, ...other }) => {
  const [isOpen, setIsOpen] = useState(false)
  //const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  const menuItemRef = useRef(null)
  const normMenuItemId = id 

  const handleItemKeyDown= (ev) => {

    console.log('handleItemKeyDown', ev);
    if ((ev.key !== 'ArrowRight' && ev.key !== 'Enter') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) return
    ev.preventDefault()
    ev.stopPropagation()
    setIsOpen(true)
  }

  const handleMenuKeyDown = (ev) => {

    console.log('handleMenuKeyDown', ev);
    ev.stopPropagation()
    if ((ev.key !== 'ArrowLeft' && ev.key !== 'Escape') || ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) return
    ev.preventDefault()
    setIsOpen(false)
  }

  return (
    <MenuItem
      {...other}
      onKeyDown={handleItemKeyDown}
      ref={menuItemRef}
      sx={[{ justifyContent: 'space-between' }, isOpen && { backgroundColor: 'action.hover' }]}
      //onMouseEnter={open}
      onMouseLeave={close}
      onClick={()=> {setIsOpen(prev => !prev)}}
      id={normMenuItemId}
    >
      <span>{label}</span>
      <NavigateNextIcon sx={{ fontSize: '1.2rem' }} />
      <Menu
        TransitionProps={{ onExited: () => menuItemRef.current?.focus() }} // If you need a submenu more than one level deep, it’s probably better to use context to check if it’s a top level component to focus only once
        disableRestoreFocus
        getContentAnchorEl={null}
        onKeyDown={handleMenuKeyDown}
        sx={{
          pointerEvents: 'none',
          '& .MuiList-root': {
            pointerEvents: 'auto'
          }
        }}
        MenuListProps={{
          ...MenuProps?.MenuListProps,
          'aria-labelledby': normMenuItemId,
          onMouseLeave: close
        }}
        anchorEl={menuItemRef.current}
        open={isOpen}
        onClose={close}
        anchorOrigin={MenuProps?.anchorOrigin ?? { vertical: 'center', horizontal: 'left' }}
        transformOrigin={
          MenuProps?.transformOrigin ?? {
            vertical: 'center',
            horizontal: 'left'
          }
        }
      >
        {children}
      </Menu>
    </MenuItem>
  )
}

export default MoreMenuItem
