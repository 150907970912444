import React, { useState, useMemo, useCallback, useEffect, useContext, useRef, memo } from "react";
import fileDefault from '../../assets/file-blank-solid-240.png';
import fileCSS from '../../assets/file-css-solid-240.png';
import filePdf from '../../assets/file-pdf-solid-240.png';
import filePng from '../../assets/file-png-solid-240.png';
//import { useParams, useHistory } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import HeaderTicket from "./HeaderTicket"
import {
	Container,
	DialogActions,
	Button,
	CircularProgress,
	Card,
	CardContent,
	Grid,
	IconButton,
	Link,
	MenuItem,
	Box,
	Divider,
	Menu
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Edit } from "@material-ui/icons";
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParse from 'react-html-parser';
import api from "../../services/api";
import Title from "../../components/Title";
import { getBackendUrl } from "../../config";
import { Formik, Form } from "formik";
import { green } from "@material-ui/core/colors";
import { useDropzone } from 'react-dropzone';
import './index.css';
import * as Yup from "yup";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
	editor: {
		background: "#fff",
		color: "#000",
		marginTop: 30
	},
	titulo: {
		background: "#ccc",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	btnWrapper: {
		position: "relative",
	},
	card: {
		background: "#f0f0f0",
		marginBottom: "10px"
	},
	dragContent: {
		marginTop: 5,
		background: "#fff",
		color: "#000",

	},
	drag: {
		marginTop: 5,
		background: "#f0f0f0",
		borderStyle: "dashed",
		textAlign: "center",
		borderColor: "#ccc",
		color: "#000",
		cursor: "pointer",
	},
	imageDrog: {
		position: "relative",
		display: "flex",
		marginBottom: "10px",
		backgroundColor: "var(--input-bg)",
		padding: "15px",
		borderRadius: "20px",
		width: "50px",
		marginRight: "20px",
	},
	root: {
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	fontHeaderCategoria: {
		fontSize: '12px',
		color: "#9632fb"
	},
	fontHeader: {
		fontSize: '11px'
	},
	fontHeaderAlter: {
		fontSize: '11px',
		color: 'red'
	},
	selectObrigatorio: {
		"&:after": {
			borderBottomColor: "#2576d2",
		},
		"& .MuiSvgIcon-root": {
			color: "#2576d2",
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#2576d2'
		},

	},
	textFieldObrigatorio: {
		'& .MuiInput-underline:after': {
			borderBottomColor: '#2576d2',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#2576d2',
			},
		},
	},
	selectOpcional: {
		"&:after": {
			borderBottomColor: "#DDA0DD",
		},
		"& .MuiSvgIcon-root": {
			color: "#DDA0DD",
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#DDA0DD'
		},

	},
	textFieldOpcional: {
		'& .MuiInput-underline:after': {
			borderBottomColor: '#DDA0DD',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#DDA0DD',
			},
		},
	}

}));


const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '5px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#6fbbd3',
	color: '#fff',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	cursor: "pointer",
	marginTop: 5
};

const focusedStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};



const RegistrationTicket = memo(({ ticket, contact, onCloseTicket }) => {

	//const { ticketId } = useParams();
	//const history = useHistory();

	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState();
	const [btnSalvar, setBtnSalvar] = useState(false);
	const [closedWindows, setClosedWindows] = useState(true);
	

	const [messageId, setMessageId] = useState({});
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [dataMsg, setDataMsg] = useState("");
	const [dataMsgId, setDataMsgId] = useState('');
	const [fileList, setFileList] = useState([]);
	const [selectedContact, setSelectedContact] = useState([]);
	const [userId, setUserId] = useState('');
	const [categoryId, setCategoryId] = useState('');

	const [statusId, setStatusId] = useState('');
	const [priorityId, setPriorityId] = useState('');

	const { user } = useContext(AuthContext);

	const initialState = {
		title: '', subtitle: '', time: 0, isEdit: false
	};
	const [rticket, setRticket] = useState(initialState);

	const refToTop = useRef();

	useEffect(() => {

		const delayDebounceFn = setTimeout(() => {
			const fetchTicket = async () => {
				try {

					if (ticket.id) {

						const { data } = await api.get("/rtickets/" + ticket.id);

						//console.log('rtickets', data);

						setRticket(prevState => {
							return { ...prevState, ...data };
						});
						setSelectedContact(data.members);
						setUserId(data.userExecute?.id);

						setStatusId(data.status?.id);
						setPriorityId(data.priority?.id);

					} else {
						setPriorityId(6);
						setStatusId(4);

						if (contact) {
							setSelectedContact([contact])
						}
					}
				} catch (err) {

					toastError(err);
				}
			};
			fetchTicket();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [ticket, contact]);


	const onDrop = useCallback(acceptedFiles => {
		//console.log('acceptedFiles', acceptedFiles);

		if (acceptedFiles.length > 0) {

			const novoArray = acceptedFiles.filter(objeto =>
				!fileList.some(objeto2 =>
					objeto.path === objeto2.path
				)
			);
			let newArray = fileList.concat(novoArray);
			setFileList(newArray);
			setDataMsg("");
		}
	}, [fileList])

	const fileRemove = (file) => {
		const updatedList = [...fileList];
		updatedList.splice(fileList.indexOf(file), 1);
		setFileList(updatedList);
	}

	const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject
	} = useDropzone({ onDrop });

	const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isFocused,
		isDragAccept,
		isDragReject
	]);

	const handleChangeEditor = (editor) => {
		setDataMsg(editor);
	}

	const ImageConfig = {
		default: fileDefault,
		pdf: filePdf,
		png: filePng,
		css: fileCSS
	}

	const TicketSchema = Yup.object().shape({
		title: Yup.string().min(2, "Too Short!").required("Required"),
		time: Yup.number().nullable(true)
	});

	function uploadAdapter(loader) {



		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					const body = new FormData();
					loader.file.then((file) => {

						body.append("medias", file);

						api.post(`/uploadFiles`, body)
							.then((res) => {
								resolve({
									default: `${getBackendUrl()}public/${res.data.filename}`
								});
							})
							.catch((err) => {
								console.log(err);
								reject(err);
							});
					});
				});
			}
		};
	}

	function uploadPlugin(editor) {
		editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
			return uploadAdapter(loader);
		};
	}



	const handleTicket = async (values) => {

		//console.log('values', values);
		//console.log('dataMsg', dataMsg);

		setBtnSalvar(true);

		const userContext = { id: user.id, name: user.name };

		const data = {
			...values,
			dataMsg: { id: dataMsgId, body: dataMsg },
			selectedContact,
			user: userContext,
			userExecuteId: userId,
			statusId,
			priorityId,
			categoryId,
			fileList
		};

		// enviar arquivo anexados
		for (const file of fileList) {

			if (!file.hasOwnProperty("filename")) {

				const body = new FormData();
				body.append("medias", file);
				await api.post(`/uploadFiles`, body)
					.then((response) => {
						//console.log('uploadFiles', response);
						file.filename = response.data.filename;

					})
					.catch((err) => {
						toastError(err);
						console.log(err);
					});
			}
		}
		//console.log('data', data);


		//envia os dados para o servidor
		await api.post(`/rtickets`, data).then((response) => {
			//console.log('response: ', response);

			setDataMsgId('');
			setDataMsg('');
			setCategoryId('');
			setFileList([]);
			setBtnSalvar(false);


			setRticket(prevState => {
				return { ...prevState, ...response?.data, ...{ subtitle: '' } };
			});

			toast.success("Salvo com Sucesso.", { position: "top-center" });

			if(closedWindows)
				handleBack();

		}).catch((err) => {
			toastError(err);
			console.log(err);
		});

	}

	const handleMessagemEdit = (value) => {

		setDataMsg(value?.body || '');
		setDataMsgId(value?.id || '');

		//console.log('edit', value);

		setRticket((prevState) => {
			return { ...prevState, ...{ subtitle: value.subtitle } };
		});
		setCategoryId(value.rcategory?.id || '');
		setFileList(JSON.parse(value?.mediaUrl || ''));

		if (refToTop.current)
			refToTop.current.scrollIntoView();

		//console.log('handleMessagemEdit: ', value);
		//console.log('rticket: ', rticket);
	}

	const handleDeleteMessage = () => {

		//console.log('values', value);
		setAnchorEl(null);

		api.put(`/rmessages/${messageId}`, {}).then((response) => {
			//console.log('response: ', response);

			setRticket(prevState => {
				return { ...prevState, ...response?.data, ...{ subtitle: '' } };
			});

			setMessageId({});
			toast.success("Delete Message com Sucesso.", { position: "top-center" });

		}).catch((err) => {
			toastError(err);
			console.log(err);
		});


	}

	const handleOpenConfirmationModal = msg => {
		setConfirmationOpen(true);
		setMessageId(msg.id)
	};

	const handleClearEdit = () => {

		setDataMsgId('');
		setDataMsg('');
		setCategoryId('');
		setFileList([]);

		setRticket(prevState => {
			return { ...prevState, ...{ subtitle: '' } };
		});
	}

	const handleBack = () => {
		onCloseTicket(false);
	}

	const regexBody = (value) => {

		let re = /<figure.*?url="(.*?)".*?figure>/;
		let name = value;
		let body = name.replace(re, '$1');

		return body;
	};

	const handleClosed = (event) => {
		setClosedWindows(event.target.checked);
	};



	return (

		<Container ref={refToTop} >
			<Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
				<Title>Registro Ticket</Title>
				<Button
					size="small"
					color="secondary"
					variant="outlined"
					onClick={handleBack}
				>VOLTAR</Button>
			</Box>
			<ConfirmationModal
				title={`Deseja Realmente Excluir Mensagem ID ${messageId}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteMessage}
			>
				Não Será possível recuperar
			</ConfirmationModal>
			<Formik
				initialValues={rticket}
				enableReinitialize={true}
				validationSchema={TicketSchema}
				onSubmit={(values, actions) => {
					setTimeout(() => {
						handleTicket(values);
						actions.setSubmitting(false);
					}, 400);
				}}
			>

				{({ values, touched, errors, isSubmitting }) => (
					<Form>

						<HeaderTicket
							values={values}
							touched={touched}
							errors={errors}

							divider={true}
							opcional="(Opcional)"

							statusId={statusId}
							setStatusId={setStatusId}

							selectedContact={selectedContact}
							setSelectedContact={setSelectedContact}

							priorityId={priorityId}
							setPriorityId={setPriorityId}

							userId={userId}
							setUserId={setUserId}

							categoryId={categoryId}
							setCategoryId={setCategoryId}


						/>


						<div className={classes.editor} id="id-rticket">

							{dataMsgId && (
								<div style={{ display: "flex", justifyContent: 'flex-end' }}>
									<IconButton
										onClick={() => handleClearEdit()}
									>
										<DeleteSweepIcon />
									</IconButton>
								</div>
							)}
							<CKEditor

								config={{
									extraPlugins: [uploadPlugin],
									height: 500
								}}

								onReady={(editor) => {
									editor.editing.view.change((writer) => {
										writer.setStyle(
											"min-height",
											"10em",
											editor.editing.view.document.getRoot()
										);
									});
								}}

								data={dataMsg}

								editor={ClassicEditor}

								onBlur={(event, editor) => { }}
								onFocus={(event, editor) => { }}
								onChange={(event, editor) => {
									handleChangeEditor(editor.getData());
								}}

							/>
						</div>

						<div {...getRootProps({ style })}>
							<input {...getInputProps()} />
							<p>Click ou arraste para selecionar arquivo</p>
						</div>

						<section style={{ marginTop: '10px' }}>
							<Grid
								container
								spacing={1}
							>
								{
									fileList.map((item, index) => (
										<Grid
											key={index}
											container
											direction="row"
											justifyContent="flex-start"
											alignItems="center"
											style={{
												borderColor: '#eeeeee',
												borderStyle: 'dashed',
												marginTop: 5
											}}
										>
											<img style={{ width: "25px", marginRight: "20px" }} src={ImageConfig['default']} alt="" />
											<div>
												<p>{item?.name || item?.path}</p>
												<p>{item?.size}B</p>
											</div>
											<IconButton aria-label="delete" onClick={() => fileRemove(item)}>
												<DeleteIcon />
											</IconButton>

										</Grid>
									))
								}
							</Grid>
						</section>
						<DialogActions>

							<FormControlLabel
								control={<Switch checked={closedWindows} onChange={handleClosed} />}
								label="Closed Windows"
							/>

							<Button
								type="submit"
								color="primary"
								disabled={btnSalvar}
								variant="contained"
								className={classes.btnWrapper}
							>
								Salvar
								{isSubmitting && (
									<CircularProgress
										size={24}
										className={classes.buttonProgress}
									/>
								)}
							</Button>
						</DialogActions>
					</Form>

				)}
			</Formik>
			{rticket.rmessages?.map((item, index, array) => (

				item.isDelete ? (

					<Card key={index} className={classes.card}>
						<CardContent style={{ color: '#000' }}>
							<div>
								mensagem deletada - ID {item.id}
							</div>
						</CardContent>
					</Card>
				)
					:
					(
						<Card key={index} className={classes.card}>

							<div style={{ color: 'black', background: '#B0C4DE' }}>

								<div style={{ fontSize: '8px', display: "flex", justifyContent: 'space-between', marginBottom: '0px' }}>
									<div>
										ID MSN : {item.id}
									</div>

									{item.subtitle && (
										<div style={{ fontSize: '16px', marginTop: '10px' }}>
											<strong> {item.subtitle} </strong>
										</div>
									)}

									<div style={{ display: "flex", justifyContent: 'flex-end' }}>

										<IconButton
											fontSize="small"
											onClick={() => handleMessagemEdit(item)}
										>
											<Edit
												fontSize="small"

											/>
										</IconButton>
										<div>
											<IconButton
												fontSize="small"
												aria-label="more"
												aria-controls="long-menu"
												aria-haspopup="true"
												onClick={(event) => { setAnchorEl(event.currentTarget); setOpen(item); }}
											>
												<MoreVertIcon fontSize="small" />
												{array.length - index}
											</IconButton>

											{anchorEl && (

												<Menu
													id="long-menu"
													anchorEl={anchorEl}
													keepMounted
													open={open === item}
													onClose={() => { setAnchorEl(null) }}

												>
													<MenuItem key={index} onClick={() => handleOpenConfirmationModal(item)}>
														Excluir
													</MenuItem>

												</Menu>
											)}
										</div>
									</div>
								</div>




								<div style={{ marginLeft: '10px' }}>

									{item.rcategory?.title &&
										<div className={classes.fontHeaderCategoria}>
											<strong>Categoria:</strong> {item.rcategory?.title}
										</div>
									}
									<div className={classes.fontHeader}>
										<strong>Usuário Criação: </strong>{item.userCreate?.name}
										<strong>   Data Criação: </strong>{format(parseISO(item.createdAt), "dd/MM/yy HH:mm:ss")}
									</div>
									{item.userUpdate?.name &&
										<div className={classes.fontHeaderAlter}>
											<strong>Usuário Alterou mensagem: </strong>{item.userUpdate?.name}
											<strong>   Data Ultima Alteração: </strong>{format(parseISO(item.updatedAt), "dd/MM/yy HH:mm:ss")}
										</div>
									}
								</div>
								<Divider />
							</div>


							<CardContent style={{ color: '#000' }}>


								{ReactHtmlParse(regexBody(item.body))}

								{item.mediaUrl && JSON.parse(item.mediaUrl).map((item, index) => {
									return (
										<Link
											style={{ wordWrap: "break-word", display: "block" }}
											key={index}
											href={`${getBackendUrl()}public/${item.filename}`}>
											{item.path}
										</Link>
									)
								})}
							</CardContent>
						</Card>
					)

			))}


		</Container>

	);
});

export default RegistrationTicket;
