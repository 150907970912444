import React, {useState, useEffect} from "react";
import Modal from '@material-ui/core/Modal';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';

const Player = ({ modalOpen}) => {

    const [openModal, setOpenModal] = useState(false);
    const [rotate, setRotate] = useState(0);

    const toggleModal = ()=>{
        setOpenModal(false);
    }

    const chageRotate = ()=>{
        setRotate(rotate + 90);
    }

    useEffect(() => {
        setOpenModal(modalOpen?.open);
        setRotate(0);
    }, [modalOpen]);

    return (

 
            
        <Modal
            open={openModal}
            onClose={toggleModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            
            <div>
                <Fab
                    onClick={chageRotate}
                >
                    <AutorenewIcon/>
                </Fab>
                <video
                    src={modalOpen?.urlVideo}
                    controls
                    style={{
                        maxWidth: "calc(100% - 30px)",
                        maxHeight: "600px",
                        transform: "rotate("+rotate+"deg)"
                    }}
                />
                <Fab
                    onClick={toggleModal}
                >
                    <CloseIcon/>
                </Fab>
            </div>

        </Modal>

    );
}


export default Player;