import React, { useState, useEffect, useReducer, useRef } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {
  AccessTime,
  Block,
  Edit,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
} from "@material-ui/icons";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Fab from '@material-ui/core/Fab';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Player from "../MessagesList/reactPlayer";
import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import ModalImageSticker from "../ModalImageSticker";
import Badge from '@material-ui/core/Badge';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import InputBase from "@material-ui/core/InputBase";
import moment from 'moment-timezone';

import api from "../../services/api";
import toastError from "../../errors/toastError";
import Twemoji from 'react-twemoji';

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  messagesList: {
    //backgroundImage: theme.backgroundImage,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    textAlign: "justify",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    backgroundColor: theme.messageLeft.backgroundColor,
    color: theme.messageLeft.color,
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 10,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: theme.quotedContainerLeft.backgroundColor,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 400,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    wordBreak: "break-word",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {

    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    textAlign: "justify",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 10,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 400,
    height: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: theme.messageContactName.color,
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
  scrollToBottom: {
    position: 'fixed',
    margin: '0px',
    top: 'auto',
    right: '10px',
    bottom: '150px',
    left: 'auto'
  },
  header: {
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "73px",
    justifyContent: "flex-start",
  },

  drawer: {
    "& .MuiDrawer-paper": {
      width: 400, //drawer width
      maxHeight: 'calc(100% - 80px)',
      position: "absolute", //imp
    }
  },
  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 25,
    padding: "10px",
    outline: "none",
  },

}));

const reducer = (state, action) => {

  if (action.type === "LOAD_MESSAGES") {

    const messages = action.payload;

    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesListDrawer = ({ openDrawer, closeDrawerHandle, contactId, isGroup }) => {


  const classes = useStyles();

  const [searchParam, setSearchParam] = useState({input: "", createdAt: "", flag: false});
  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const lastMessageRef = useRef();
  const firstMessageRef = useRef();
  const [finalPageNumber, setFinalPageNumber] = useState(true);

  const currentContactId = useRef(contactId);
  const [modalOpen, setModalOpen] = useState({ urlVideo: '', open: false });
  const [messagePendent, setMessagePendent] = useState(0);
  const [searchInput, setSearchInput] = useState("");


  useEffect(() => {
    reset();
    currentContactId.current = contactId;
  }, [contactId]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;

    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {


          //console.log(pageNumber, contactId, searchParam);

          const { data } = await api.get("/messages/search/" + contactId, {
            params: { pageNumber, searchParam },
          });

          if (isMounted) {
            //console.log('data:', data, searchParam)

            if (currentContactId.current === contactId) {
              dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
              setHasMore(data.hasMore);
              setLoading(false);

            }
            if (pageNumber === 1 && data.messages.length > 1) {
              scrollToBottom();
            }

            if (searchParam?.input) {
              scrollTop();
            }

            setFlag(false);
          }

        } catch (err) {
          setLoading(false);
          toastError(err);
        }

        return [];
      };
      fetchMessages();
    }, 500);
    return () => {
      isMounted = false;
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, contactId, searchParam]);


  const loadMore = () => {


    let message = messagesList.find((message, index) => index === messagesList.lenght - 1)
    
    //console.log('loadMore: ', moment(message?.createdAt).format('YYYY-MM-DD HH:mm:ss'));
    if(!flag)
      setSearchParam(prev => ({...prev, ...{createdAt: message?.createdAt ? moment(message?.createdAt).format('YYYY-MM-DD HH:mm:ss') : '', flag: false}}))

    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const reset = () => {

    dispatch({ type: "RESET" });
    setPageNumber(1);
  };

  const setSearch = () => {
    
    
    if(true){

      setFlag(true);
  
      let message = "";
      let flag = false;
  
      if(searchParam?.input !== searchInput ){
        flag = false;
        message = messagesList[messagesList.length - 1]
       
        reset();
  
      }else if(searchInput){
        flag = true;
        message = messagesList[0]
      }else{
        flag = false;
        message = {input: "", createdAt: ""}
      }
      
      //console.log('message:', message);
  
      if(searchParam?.input !== searchInput || searchParam?.createdAt !== message?.createdAt){
        setSearchParam({input: searchInput, createdAt: message?.createdAt ? moment(message?.createdAt).format('YYYY-MM-DD HH:mm:ss') : '', flag}) 
        //console.log('searchParam DIF:', searchParam); 
      }
    }
    
    
  };


  const scrollToBottom = () => {

    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }

  };

  const scrollTop = () => {

    if (firstMessageRef.current) {
      firstMessageRef.current.scrollIntoView({});
    }

  };


  const downScroll = () => {

    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }

    setMessagePendent(0);

  };


  const handleScroll = (e) => {
    if (!hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    const size = scrollHeight - scrollTop - 50;
    if (clientHeight > size) {
      setMessagePendent(0);
    }

    if (scrollTop === 0) {
      document.getElementById("messagesList1").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {

      if (finalPageNumber) {
        setFinalPageNumber(false);
        loadMore();
      }

    } else {
      setFinalPageNumber(true);
    }
  };

  const checkMessageMedia = (message) => {

    if (message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    else if (message.mediaType === "vcard") {
      //console.log("vcard")
      //console.log(message)
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      if (typeof obj[0] === 'undefined') {
        console.log('LOG VcardPreview', obj[0]);
        return;
      }
      return <VcardPreview contact={contact} numbers={obj[0].number} />
    }

    else if (message.mediaType === "sticker") {

      return <ModalImageSticker imageUrl={message.mediaUrl} />;

    }
    else if (message.mediaType === "image") {

      return <ModalImageCors imageUrl={message.mediaUrl} />;

    } else if (message.mediaType === "audio") {

      return (
        <audio controls>
          <source src={message.mediaUrl} type="audio/ogg"></source>
        </audio>
      );

    } else if (message.mediaType === "video") {

      return (

        <div>
          <video
            className={classes.messageMedia}
            src={message.mediaUrl}
            controls
          />
          <Fab
            key={message.id}
            onClick={() => {
              setModalOpen({ urlVideo: message.mediaUrl, open: true })
            }}>
            <PlayCircleOutlineIcon
              size="large"
              color="primary"
            />
          </Fab>
        </div>
      );

    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          <div className={classes.dailyTimestampText} ref={firstMessageRef}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {

      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {

    return (


      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg} style={{ img: { with: '10px' } }}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}

          {(message.quotedMsg.mediaUrl || message.quotedMsg.mediaType === "location" || message.quotedMsg.mediaType === "vcard"
            //|| message.mediaType === "multi_vcard" 
          ) && checkMessageMedia(message.quotedMsg)}
          {message.quotedMsg?.body}
        </div>
      </div>

    );
  };

  const getStringColor = (string = '', saturation = 100, lightness = 75) => {

    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
  };

  const renderMessages = () => {

    if (messagesList.length > 0) {

      const viewMessagesList = messagesList.map((message, index) => {

        if (!message.fromMe) {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageLeft}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}

                >
                  <ExpandMore />
                </IconButton>
                {isGroup && (
                  <span className={classes.messageContactName} style={{ color: getStringColor(message.contact?.number) }}>
                    {message.contact?.name}
                  </span>
                )}
                {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard"
                  //|| message.mediaType === "multi_vcard" 
                ) && checkMessageMedia(message)
                }
                {
                  (message.mediaType === "call_log") && (
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" width="20" height="17">
                        <path fill="#df3333" d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"></path>
                      </svg> <span>Chamada de voz/vídeo</span>
                    </div>
                  )
                }

                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}>

                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.isEdit && (
                    <Edit
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}

                  {message.quotedMsg && renderQuotedMessage(message)}
                  <Twemoji options={{ className: 'twemoji' }}>
                    <MarkdownWrapper>
                      {message.body}
                    </MarkdownWrapper>

                  </Twemoji>


                  <span className={classes.timestamp}>
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={message.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div className={classes.messageRight}>
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}

                >
                  <ExpandMore />
                </IconButton>
                {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard"
                  //|| message.mediaType === "multi_vcard" 
                ) && checkMessageMedia(message)}
                <div
                  className={clsx(classes.textContentItem, {
                    [classes.textContentItemDeleted]: message.isDeleted,
                  })}
                >
                  {message.isDeleted && (
                    <Block
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.isEdit && (
                    <Edit
                      color="disabled"
                      fontSize="small"
                      className={classes.deletedIcon}
                    />
                  )}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  <Twemoji options={{ className: 'twemoji' }}>
                    <MarkdownWrapper>
                      {message.body}
                    </MarkdownWrapper>

                  </Twemoji>


                  <span className={classes.timestamp}>
                    {format(parseISO(message.createdAt), "HH:mm")}
                    {renderMessageAck(message)}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  return (

    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={openDrawer}

    >
      <div className={classes.header}>
        <IconButton onClick={closeDrawerHandle}>
          <CloseIcon style={{ color: "#30a3e6" }} />
        </IconButton>
        <InputBase
          className={classes.searchInput}
          placeholder="Pesquisar Message"
          type="search"
          onChange={event => {                                 //adding the onChange event
            setSearchInput(event.target.value)
          }}
        />
        <IconButton onClick={() => setSearch()}>
          <ArrowUpwardIcon style={{ color: "#30a3e6" }} />
        </IconButton>
      </div>

      <div className={classes.messagesListWrapper}>

        <div
          id="messagesList1"
          className={classes.messagesList}
          onScroll={handleScroll}
        >
          {messagesList.length > 0 ? renderMessages() : []}

          {messagePendent > 0 && (<Badge
            badgeContent={messagePendent}
            color="secondary"
            overlap="rectangular"
            className={classes.scrollToBottom}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <Fab size="small"
              aria-label="add"
              onClick={() => downScroll()}>
              <ExpandMoreIcon
                size="small"
              />
            </Fab>
          </Badge>)}

        </div>
        {loading && (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        )}
        {
          modalOpen.open && (
            <Player modalOpen={modalOpen} />
          )
        }
      </div>

    </Drawer>
  );
};

export default MessagesListDrawer;