import { useEffect, useReducer } from "react";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const reducer = (state, action) => {

	if (action.type === "LOAD_RTICKETS") {
		const rtickets = action.payload;

		return [...rtickets];
	}

	if (action.type === "UPDATE_RTICKETS") {
		const rticket = action.payload;

		if (state) {
			const rticketIndex = state.findIndex(s => s.id === rticket.id);

			if (rticketIndex !== -1) {
				//status rticket fechado
				if (rticket.status?.id === 4) {
					state.splice(rticketIndex, 1);
				}

				return [...state];

			} else {

				if (rticket.status?.id === 4) {
					return [...state];
				}else{
					return [rticket, ...state];
				}	
			}
		}
	}

	if (action.type === "DELETE_RTICKET") {
		const ticketsId = action.payload;
	
		const ticketsIndex = state.findIndex((u) => u.id === ticketsId);
		if (ticketsIndex !== -1) {
		  state.splice(ticketsIndex, 1);
		}
		return [...state];
	  }


	if (action.type === "RESET") {
		return [];
	}
};

const useRTickets = () => {

	const [rtickets, dispatch] = useReducer(reducer, []);

	useEffect(() => {
	
		let isMounted = true;

		const fetchSession = async () => {
			try {
				const { data } = await api.get("/rtickets/", {
					params: { searchParam: { notStatusId: 4 }, pageNumber: 1 },
				});

				if(isMounted) {
					dispatch({ type: "LOAD_RTICKETS", payload: data.rtickets });
				}
				
			} catch (err) {
	
				toastError(err);
			}
		};
		fetchSession();

		return () => {
            isMounted = false;
        }
	}, []);

	useEffect(() => {
		const socket = openSocket();

		socket.on("rticket", data => {
			if (data.action === "update") {
				dispatch({ type: "UPDATE_RTICKETS", payload: data.rticket });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_RTICKET", payload: +data.ticketId });
			  }
		});

		return () => {
			socket.disconnect();
		};

	}, []);

	return rtickets;
};

export default useRTickets;
