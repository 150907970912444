import React, { useEffect, useState } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import {
	CircularProgress
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import moment from 'moment-timezone';
import IconButton from "@material-ui/core/IconButton";
import SyncIcon from '@material-ui/icons/Sync';
import { green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";

//import useTickets from "../../hooks/useTickets"

//import { AuthContext } from "../../context/Auth/AuthContext";

import { i18n } from "../../translate/i18n";

import Chart from "./Chart"
import api from "../../services/api";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 140,
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	},
	circleLoading: {
		color: green[500],
		position: "absolute",
		opacity: "70%",
		top: 0,
		left: "50%",
		marginTop: 50,
	},
}))

const Dashboard = () => {

	const classes = useStyles()
	const history = useHistory();
	const date = moment();
	//const { user } = useContext(AuthContext);
	//const [userQueueIds, setUserQueueIds] = useState([]);
	const [data, setData] = useState([]);
	const [selectedDateInicial, setSelectedDateInicial] = useState(date.tz('America/Sao_Paulo'));
	const [selectedDateFinal, setSelectedDateFinal] = useState(date.tz('America/Sao_Paulo'));
	const [loading, setLoading] = useState(false);


	useEffect(() => {

		fetchDashboard();

		/*
		if (user?.id && user.queues && user.queues.length > 0) {
			setUserQueueIds(user.queues.map(q => q.id));
		}
		*/

		// eslint-disable-next-line
	}, []);

	/*
const GetTickets = (status, showAll, withUnreadMessages) => {

	const { count } = useTickets({
		status: status,
		showAll: showAll,
		withUnreadMessages: withUnreadMessages,
		queueIds: JSON.stringify(userQueueIds)
	});
	return count;
}*/

	const fetchDashboard = async () => {
		try {

			let searchParam = {
				dataInicial: moment(selectedDateInicial).format('YYYY-MM-DD'),
				dataFinal: moment(selectedDateFinal).format('YYYY-MM-DD')
			}
			setLoading(true);
			//console.log('searchParam ', searchParam);

			const { data } = await api.get("/dashboard/", {
				params: { searchParam },
			});

			setData(data);
			//console.log(data);
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const SyncData = () => {
		fetchDashboard();
	}

	const GetAtendimentos = (status) => {

		let count = 0;
		for (let index = 0; index < data?.atendimentos?.length; index++) {
			const element = data?.atendimentos[index];
			if (element['ticket_status'] === status) {
				count++;
			}
		}
		return count;
	}

	const openWindowTicket = () => {
		history.push(`/tickets`);
	}

	const GetTicketsPorAnalistas = () => {

		let array = [];
		for (let index = 0; index < data?.rtickets?.length; index++) {
			const element1 = data?.rtickets[index];

			let flag = true;

			for (let index = 0; index < array.length; index++) {
				const element2 = array[index];

				if (element1['userCreate.id'] === element2.id) {
					element2.cont = element2.cont + 1;
					flag = false;
					break;
				}

			}

			if (flag) {

				if (element1['userCreate.id']) {
					const obj = {
						id: element1['userCreate.id'],
						name: element1['userCreate.name'],
						cont: 1
					}

					array.push(obj);
				}
			}
		}
		return array.sort(mycomparator);
	}

	const GetTicketsPorClientes = () => {

		let array = [];
		for (let index = 0; index < data?.rtickets?.length; index++) {
			const element1 = data?.rtickets[index];

			let flag = true;

			for (let index = 0; index < array.length; index++) {
				const element2 = array[index];

				if (element1['members.id'] === element2.id) {
					element2.cont = element2.cont + 1;
					flag = false;
					break;
				}

			}

			if (flag) {

				if (element1['members.id']) {
					const obj = {
						id: element1['members.id'],
						name: element1['members.name'],
						cont: 1
					}

					array.push(obj);
				}
			}
		}
		return array.sort(mycomparator);
	}

	const GetAnalistasEmAtendimentos = () => {

		let array = [];
		for (let index = 0; index < data?.atendimentos?.length; index++) {
			const element1 = data?.atendimentos[index];

			let flag = true;

			for (let index = 0; index < array.length; index++) {
				const element2 = array[index];

				if (element1['user_id'] === element2.id && element1['ticket_status'] === 'open') {
					element2.cont = element2.cont + 1;
					flag = false;
					break;
				}

			}

			if (flag) {

				if (element1['user_id'] && element1['ticket_status'] === 'open') {
					const obj = {
						id: element1['user_id'],
						name: element1['user_name'],
						cont: 1
					}

					array.push(obj);
				}
			}
		}
		return array.sort(mycomparator);
	}

	const GetAnalistasPorCliente = () => {

		let array = [];
		for (let index = 0; index < data?.atendimentos?.length; index++) {
			const element1 = data?.atendimentos[index];

			let flag = true;

			for (let index = 0; index < array.length; index++) {
				const element2 = array[index];

				if (element1['user_id'] === element2.id) {
					element2.cont = element2.cont + 1;
					flag = false;
					break;
				}

			}

			if (flag) {

				if (element1['user_id']) {
					const obj = {
						id: element1['user_id'],
						name: element1['user_name'],
						cont: 1
					}

					array.push(obj);
				}
			}
		}
		return array.sort(mycomparator);
	}

	function mycomparator(a, b) {
		return parseInt(b.cont) - parseInt(a.cont);
	}

	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>

				{loading && (
					<div>
						<CircularProgress className={classes.circleLoading} />
					</div>
				)}
				<Box sx={{ display: 'flex', flexWrap: 'wrap', flex: 12 }}>
					<MuiPickersUtilsProvider utils={DateFnsUtils} >
						<KeyboardDatePicker
							disableToolbar
							inputVariant="outlined"
							format="dd/MM/yyyy"
							margin="dense"
							id="date-picker-inline"
							label="Date Inicial"
							value={selectedDateInicial}
							maxDate={selectedDateFinal}
							onChange={(date) => { setSelectedDateInicial(date) }}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}

							style={{ minWidth: 200, flex: 2, marginRight: 10 }}
						/>
						<KeyboardDatePicker
							disableToolbar
							inputVariant="outlined"
							format="dd/MM/yyyy"
							margin="dense"
							id="date-picker-inline"
							label="Date Final"
							value={selectedDateFinal}
							minDate={selectedDateInicial}
							onChange={(date) => { setSelectedDateFinal(date) }}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}

							style={{ minWidth: 200, flex: 2, marginRight: 10 }}
						/>
					</MuiPickersUtilsProvider>
					<IconButton onClick={() => SyncData()}>
						<SyncIcon color="secondary" />
					</IconButton>
				</Box>
				<Grid container spacing={3} sx={{ display: 'flex', flexWrap: 'wrap', flex: 12 }}>
					<Grid item style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
						<Paper
							className={classes.customFixedHeightPaper}
							style={{ overflow: "hidden" }}

						>

							<IconButton onClick={() => { openWindowTicket() }}>
								<Typography component="h3" variant="h6" color="primary" paragraph align="center">
									{i18n.t("dashboard.messages.inAttendance.title")}
								</Typography>
							</IconButton>
							<Grid item>
								<Typography component="h1" variant="h4" align="center">
									{GetAtendimentos('open')}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<IconButton onClick={() => { openWindowTicket() }}>
								<Typography component="h3" variant="h6" color="primary" paragraph align="center">
									{i18n.t("dashboard.messages.waiting.title")}
								</Typography>
							</IconButton>
							<Grid item>
								<Typography component="h1" variant="h4" align="center">
									{GetAtendimentos('pending')}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph align="center">
								{i18n.t("dashboard.messages.closed.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4" align="center">
									{GetAtendimentos("closed")}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart data={data?.atendimentos} />
						</Paper>
					</Grid>
					<Grid item style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
						<Paper >
							<Typography component="h3" variant="h6" color="primary" paragraph align="center">
								Analistas e Tickets
							</Typography>
							<List>
								{GetTicketsPorAnalistas()?.map((item) => (
									<ListItem key={item.id}>
										<ListItemText primary={item.name} />
										<ListItemText primary={item.cont} />
									</ListItem>
								))}

							</List>

						</Paper>
					</Grid>
					<Grid item style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
						<Paper >
							<Typography component="h3" variant="h6" color="primary" paragraph align="center">
								Clientes e Tickets
							</Typography>
							<List>
								{GetTicketsPorClientes()?.map((item) => (
									<ListItem key={item.id}>
										<ListItemText primary={item.name} />
										<ListItemText primary={item.cont} />
									</ListItem>
								))}

							</List>

						</Paper>
					</Grid>
					<Grid item style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
						<Paper >
							<Typography component="h3" variant="h6" color="primary" paragraph align="center">
								Analistas Em Atendimento
							</Typography>
							<List>
								{GetAnalistasEmAtendimentos()?.map((item) => (
									<ListItem key={item.id}>
										<ListItemText primary={item.name} />
										<ListItemText primary={item.cont} />
									</ListItem>
								))}

							</List>

						</Paper>
					</Grid>

					<Grid item style={{ minWidth: 200, flex: 2, marginRight: 10 }}>
						<Paper >
							<Typography component="h3" variant="h6" color="primary" paragraph align="center">
								Analistas por Cliente
							</Typography>
							<List>
								{GetAnalistasPorCliente()?.map((item) => (
									<ListItem key={item.id}>
										<ListItemText primary={item.name} />
										<ListItemText primary={item.cont} />
									</ListItem>
								))}

							</List>

						</Paper>
					</Grid>

				</Grid>
			</Container>
		</div>
	)
}

export default Dashboard