import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  FormControlLabel,
  Hidden,
  Menu,
  MenuItem,
  Switch,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    background: theme.palette.background.paper,
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: theme.palette.background.input,
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "grey",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {

    width: "100%",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
    display: "flex",

    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.input,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioPlay: {

    borderRadius: "15px",
    padding: "7px",
    border: "10px dashed #e8e",
    position: "absolute",
    left: "35%",
    bottom: "80px",
    marginLeft: "-30%",
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: theme.palette.background.input,
    padding: "2px",
    border: "1px solid #CCC",
    left: 0,
    width: "100%",
    maxHeight: "65vh",//change here
    overflow: "auto",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          background: theme.palette.background.paper,
          cursor: "pointer",
        },
      },
    },
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MessageInput = ({ ticketStatus, ticket }) => {

  //console.log('ticketStatus', ticketStatus);

  const classes = useStyles();
  const { ticketId } = useParams();

  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [sendRecording, setSendRecording] = useState(false);
  const [srcAudio, setSrcAudio] = useState('');
  const [blobAudio, setBlobAudio] = useState(null);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [typeBar, setTypeBar] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);

  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {

    //obter mensagem salvar no campo texto
    const messageRecuvery = localStorage.getItem('Ticket' + ticket);

    if (messageRecuvery) {
      setInputMessage(JSON.parse(messageRecuvery));
    } else {
      setInputMessage("")
    }
  }, [ticket]);

  const handleConnectionChange = () => {

    setOpen(true);

    const webPing = setInterval(() => {
      fetch("//google.com", {
        mode: "no-cors"
      })
        .then(() => {
          setIsDisconnected(false);
          clearInterval(webPing);
        })
        .catch(() => setIsDisconnected(true));
    }, 2000);

    return setIsDisconnected(true);
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);


  useEffect(() => {
    inputRef.current.focus();
    return () => {

      //setInputMessage("")
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  const handleChangeInput = (e) => {

    localStorage.setItem('Ticket' + ticket, JSON.stringify(e.target.value));
    //console.log(e.target.value);
    setInputMessage(e.target.value);
    handleLoadQuickAnswer(e.target.value);
  };

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value);
    setTypeBar(false);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();

    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", "");
    });

    if (inputMessage.trim() !== "") {

      const body = signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim().replace(/#/i, "");

      formData.set('body', body);
    }

    try {
      api.post(`/messages/${ticketId}`, formData)
        .catch(function (error) {
          console.log('api.post catch Error', error);
          toastError(error);
        });
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
    localStorage.removeItem('Ticket' + ticket);
    setInputMessage("");
    setShowEmoji(false);
    setReplyingMessage(null);
  };


  useEffect(() => {

    document.addEventListener("keydown", function (event) {

      if (event.code === "Enter" || event.code === "NumpadEnter") {


        let activeElement = document.activeElement;
        let myElement = document.getElementById('input-base-chat');
        let myElementRticket = document.getElementById('id-rticket');
        let myElementFormEdit = document.getElementById('form-dialog-edit');

        let tag_enviar_media = document.getElementById("send-imagem-btn");

        if (tag_enviar_media && !tag_enviar_media.disabled) {

          event.preventDefault();
          tag_enviar_media.click();
          tag_enviar_media.disabled = true;

        } else if (
          activeElement !== myElement &&
          myElement &&
          !myElementRticket &&
          !myElementFormEdit
        ) {

          //console.log('myElement')
          myElement.focus();
          event.preventDefault();
        };

      }
    });

  }, []);


  const handleSendMessage = async () => {

    if (inputMessage.trim() === "") return;

    setLoading(true);

    //adiciona point quando não estiver usuário 
    let userPoint = '';
    //console.log(user);
    if (user) {

      for (var i = 0; i < user.id; i++) {
        userPoint += '.';
      }

      if (user?.code) {
        userPoint = user?.code;
      }

      if (userPoint.length > 0) {
        userPoint += ' ';
      }

      if (inputMessage.charAt(0) === '#' || userPoint.includes('#')) {
        userPoint = '';
      }
    }


    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage && !inputMessage.includes('Mensagem Automática')
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : userPoint + inputMessage.trim().replace(/#/i, ""),
      quotedMsg: replyingMessage,
    };
    try {

      const auxMessage = inputMessage;

      api.post(`/messages/${ticketId}`, message, { timeout: 5000 })
        .catch(function (error) {
          console.log('api.post catch Error', error);
          handleConnectionChange();
          toastError("Timeout 5 seg envio atingido. Verifique sua conexão!");
          setInputMessage(auxMessage);
        });

    } catch (err) {
      handleConnectionChange();
      toastError(err);
    }

    localStorage.removeItem('Ticket' + ticket);
    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: inputMessage.substring(1) },
        });
        setQuickAnswer(data.quickAnswers);
        if (data.quickAnswers.length > 0) {
          setTypeBar(true);
        } else {
          setTypeBar(false);
        }
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blobAudio, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);

    setBlobAudio(null);
    setSendRecording(false);
    setSrcAudio('');
  };

  const handleStopUploadAudio = async () => {

    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;

      } else {

        const url = URL.createObjectURL(blob);
        //.log('url', url);
        setSrcAudio(url);
        setBlobAudio(blob);
        setSendRecording(true);
        setLoading(false);

      }
    } catch (err) {
      toastError(err);
    }


  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);

      setBlobAudio(null);
      setSendRecording(false);
      setSrcAudio('');

    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            {message.body}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };

  const renderUploadMedias = () => {
    return (
      <Paper
        id="key-enter-media"
        elevation={0}
        square
        className={classes.viewMediaInputWrapper}
      >
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
        >
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>

        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <span>
            {medias[0]?.name}
            {/* <img src={media.preview} alt=""></img> */}
          </span>
        )}
        <IconButton
          id="send-imagem-btn"
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>
    );
  };


  return (
    <Paper square elevation={0} className={classes.mainWrapper}>

      {isDisconnected && (
        <Snackbar open={open} autoHideDuration={30000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Verifique Internet. Teste Ping Google falhou!
          </Alert>
        </Snackbar>
      )}
      {medias.length > 0 && renderUploadMedias()}
      {replyingMessage && renderReplyingMessage(replyingMessage)}

      <div className={classes.newMessageBox}>
        <Hidden only={["sm", "xs"]}>
          <IconButton
            aria-label="emojiPicker"
            component="span"
            disabled={loading || recording || ticketStatus !== "open"}
            onClick={(e) => setShowEmoji((prevState) => !prevState)}
          >
            <MoodIcon className={classes.sendMessageIcons} />
          </IconButton>
          {showEmoji ? (
            <div className={classes.emojiBox}>
              <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                <Picker
                  perLine={16}
                  showPreview={false}
                  showSkinTones={false}
                  onSelect={handleAddEmoji}
                />
              </ClickAwayListener>
            </div>
          ) : null}

          <input
            multiple
            type="file"
            id="upload-button"
            disabled={loading || recording || ticketStatus !== "open"}
            className={classes.uploadInput}
            onChange={handleChangeMedias}
          />
          <label htmlFor="upload-button">
            <IconButton
              aria-label="upload"
              component="span"
              disabled={loading || recording || ticketStatus !== "open"}
            >
              <AttachFileIcon className={classes.sendMessageIcons} />
            </IconButton>
          </label>
          <FormControlLabel
            style={{ marginRight: 7, color: "gray" }}
            label={i18n.t("messagesInput.signMessage")}
            labelPlacement="start"
            control={
              <Switch
                size="small"
                checked={signMessage}
                onChange={(e) => {
                  setSignMessage(e.target.checked);
                }}
                name="showAllTickets"
                color="primary"
              />
            }
          />
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenMenuClick}
          >
            <MoreVert></MoreVert>
          </IconButton>
          <Menu
            id="simple-menu"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuItemClick}
          >
            <MenuItem onClick={handleMenuItemClick}>
              <IconButton
                aria-label="emojiPicker"
                component="span"
                disabled={loading || recording || ticketStatus !== "open"}
                onClick={(e) => setShowEmoji((prevState) => !prevState)}
              >
                <MoodIcon className={classes.sendMessageIcons} />
              </IconButton>
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick}>
              <input
                multiple
                type="file"
                id="upload-button"
                disabled={loading || recording || ticketStatus !== "open"}
                className={classes.uploadInput}
                onChange={handleChangeMedias}
              />
              <label htmlFor="upload-button">
                <IconButton
                  aria-label="upload"
                  component="span"
                  disabled={loading || recording || ticketStatus !== "open"}
                >
                  <AttachFileIcon className={classes.sendMessageIcons} />
                </IconButton>
              </label>
            </MenuItem>
            <MenuItem onClick={handleMenuItemClick}>
              <FormControlLabel
                style={{ marginRight: 7, color: "gray" }}
                label={i18n.t("messagesInput.signMessage")}
                labelPlacement="start"
                control={
                  <Switch
                    size="small"
                    checked={signMessage}
                    onChange={(e) => {
                      setSignMessage(e.target.checked);
                    }}
                    name="showAllTickets"
                    color="primary"
                  />
                }
              />
            </MenuItem>
          </Menu>
        </Hidden>
        <div className={classes.messageInputWrapper}>
          <InputBase
            id="input-base-chat"
            inputRef={(input) => {
              (input &&
                !document.getElementById('id-rticket') &&
                !document.getElementById('form-dialog-edit') &&
                input.focus());
              input && (inputRef.current = input);
            }}
            className={classes.messageInput}
            placeholder={
              ticketStatus === "open"
                ? i18n.t("messagesInput.placeholderOpen")
                : i18n.t("messagesInput.placeholderClosed")
            }

            multiline
            maxRows={5}
            minRows={1}
            value={inputMessage}
            onChange={handleChangeInput}
            disabled={recording || loading || ticketStatus !== "open"}
            onPaste={(e) => {
              ticketStatus === "open" && handleInputPaste(e);
            }}
            onKeyPress={(e) => {
              if (loading || e.shiftKey) return;
              else if (e.key === "Enter" && medias.length === 0) {
                handleSendMessage();
                e.preventDefault();
              }
            }}
          />
          {typeBar ? (
            <ul className={classes.messageQuickAnswersWrapper}>
              {quickAnswers.map((value, index) => {
                return (
                  <li
                    className={classes.messageQuickAnswersWrapperItem}
                    key={index}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => handleQuickAnswersClick(value.message)}>
                      {`${value.shortcut} - ${value.message}`}
                    </a>
                  </li>
                );
              })}
            </ul>
          ) : (
            <div></div>
          )}
        </div>
        {medias.length === 0 && (
          inputMessage ? (
            <IconButton
              aria-label="sendMessage"
              component="span"
              onClick={handleSendMessage}
              disabled={loading}
            >
              <SendIcon className={classes.sendMessageIcons} />
            </IconButton>
          ) : recording ? (

            <div className={classes.recorderWrapper}>

              <IconButton
                aria-label="cancelRecording"
                component="span"
                fontSize="large"
                disabled={loading}
                onClick={handleCancelAudio}
              >
                <HighlightOffIcon className={classes.cancelAudioIcon} />
              </IconButton>

              {loading ? (
                <div>
                  <CircularProgress className={classes.audioLoading} />
                </div>
              ) : (

                sendRecording ? (
                  <>
                    <div className={classes.audioPlay}>
                      <audio controls>
                        <source src={srcAudio} type="audio/ogg"></source>
                      </audio>
                    </div>

                    <IconButton
                      aria-label="sendRecordedAudio"
                      component="span"
                      onClick={handleUploadAudio}
                      disabled={loading}
                    >
                      <SendIcon className={classes.sendMessageIcons} />
                    </IconButton>
                  </>

                ) : (
                  <>
                    <RecordingTimer />
                    <IconButton
                      aria-label="stopRecordedAudio"
                      component="span"
                      onClick={handleStopUploadAudio}
                      disabled={loading}
                    >
                      <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
                    </IconButton>
                  </>
                )

              )}

            </div>

          ) : (
            <IconButton
              aria-label="showRecorder"
              component="span"
              disabled={loading || ticketStatus !== "open"}
              onClick={handleStartRecording}
            >
              <MicIcon className={classes.sendMessageIcons} />
            </IconButton>
          )
        )}
      </div>
    </Paper>
  );

};

export default MessageInput;
