import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { messages } from "./languages";

if (localStorage.getItem('i18nextLng') === null) {
	localStorage.setItem('i18nextLng', 'pt')
}

i18n.use(LanguageDetector).init({
	debug: false,
	defaultNS: ["translations"],
	fallbackLng: "pt",
	ns: ["translations"],
	resources: messages,
});

export { i18n };
