import React, { useContext, useEffect, useState } from "react";
import Chip from '@material-ui/core/Chip';
import { Avatar, CardHeader } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => ({
	menuColor: {
		backgroundColor: theme.palette.primary.main,
		color: '#000',
	},
	subColor: {
		backgroundColor: "#bb2124",
		color: '#000',
	}
}));

const TicketInfo = ({ contact, ticket, onClick }) => {

	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const { rtickets } = useContext(WhatsAppsContext);
	const [count, setCount] = useState(0);

	useEffect(() => {

		const rticketIndex = rtickets?.filter(f => {
			return f.members?.some(s => {
				return s.id === ticket.contactId
			})
		});

		setCount(rticketIndex.length);

	}, [rtickets, ticket]);

	return (

		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={
				<Badge badgeContent={count} color="secondary" overlap="rectangular">
					<Avatar src={contact.profilePicUrl} alt="contact_image" />
				</Badge>

			}
			title={`${contact.name} #${ticket.id}`}
			subheader={
				ticket.user && (
					<Chip
						className={ticket.user.name === user.name ? classes.menuColor : classes.subColor}
						size="small"
						label={`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`} />
				)



			}
		/>
	);
};

export default TicketInfo;
