import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

import { CssBaseline } from "@material-ui/core";
import lightBackground from "../src/assets/wa-background-light.png";
import darkBackground from "../src/assets/wa-background-dark.jpg";
//import CacheBuster from './CacheBuster';
//import api from "./services/api";
//import toastError from "./errors/toastError";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

const App = () => {
  const [locale, setLocale] = useState();


  /*
  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: { main: "#2576d2" },
      },
    },
    locale
  );*/

  const lightTheme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },

      palette: {
        primary: { main: "#30a3e6" },
        secondary: { main: "#ff9100" },
        background: {
          paper: "#ececec",
          input: "#fff",
          header: "#30a3e6",
        },
      },

      messageLeft: {
        backgroundColor: "#FFFF",
        color: "#000"
      },

      quotedContainerLeft: {
        backgroundColor: "#f0f0f0",
      },

      messageContactName: {
        color: "#5995b2"
      },

      backgroundImage: `url(${lightBackground})`,
    },
    locale
  );

  const darkTheme = createTheme(
    {
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              backgroundColor: "#202c33",
            }
          }
        }
      },
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#ffffff",
        },
      },
      palette: {
        primary: { main: "#30a3e6" },
        secondary: { main: "#ff9100" },
        background: {
          default: "#202c33",
          paper: "#202c33",
          header: "#202c33",
          input: "#080d14"
        },
        text: {
          primary: "#ffffff",
          secondary: "#A9A9A9",
        },
      },

      messageLeft: {
        backgroundColor: "#363636",
        color: "#FFFFFF"
      },

      quotedContainerLeft: {
        backgroundColor: "#1C1C1C",
      },

      messageContactName: {
        color: "#5ebb77"
      },

      backgroundImage: `url(${darkBackground})`,
    },
    locale
  );

  const [theme] = useState(localStorage.getItem("darkeMode"));

  /*
  useEffect(() => {

    const fetchDarkMode = async () => {
      try {
        const { data } = await api.get("/settings");
        const settingIndex = data.filter(s => s.key === 'darkMode');

        if (settingIndex[0].value === "enabled") {
          setTheme("dark")
        }

      } catch (err) {
        setTheme("light")
        toastError(err);
      }
    };

    fetchDarkMode();

  }, []); */


  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
    >

      <ThemeProvider theme={theme === "disabled" ? lightTheme : darkTheme}>
        <Routes />
        <CssBaseline />
      </ThemeProvider>

    </CacheBuster>
  );
};

export default App;
