import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import Drawer from "@material-ui/core/Drawer";
import ListRTickets from "../../pages/RegistrationTicket/ListRTickets"
import MainContainer from "../../components/MainContainer";
import MessagesListDrawer from "../MessageListDrawer";
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },

  ticketInfo: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {

  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerMessageListOpen, setDrawerMessageListOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [drawerOpenTicket, setDrawerOpenTicket] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');

  useEffect(() => {

    let isMounted = true;
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);

          //console.log('data', data);
          let checkAlert = false;
          setMessageAlert('');
          for (let i = 0; i < data?.contact?.extraInfo?.length; i++) {
            setMessageAlert((prevent) => prevent + data?.contact?.extraInfo[i]['name'] + ": " + data?.contact?.extraInfo[i]['value'] + ' ');
            checkAlert = true;
          }
          setOpen(checkAlert);

          if (isMounted) {

            setContact(data.contact);
            setTicket(data);
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }

        return [];
      };
      fetchTicket();
    }, 500);
    return () => {
      isMounted = false;
      clearTimeout(delayDebounceFn);
    }
  }, [ticketId, history]);

  useEffect(() => {

    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "update") {
        setTicket(data.ticket);
      }

      if (data.action === "delete") {
        toast.success("Ticket deleted sucessfully.");
        history.push("/tickets");
      }
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerCloseMessageList = () => {
    setDrawerMessageListOpen((prev) => !prev);
  };

  const handleDrawerCloseTicket = () => {
    setDrawerOpenTicket(true);
  };

  return (
    <div className={classes.root} id="drawer-container">

      <Drawer
        open={drawerOpenTicket}
        anchor={"right"}
        onClose={() => setDrawerOpenTicket(false)}
      >
        <MainContainer>
          <div style={{ maxWidth: window.innerWidth * 0.8, maxHeight: window.innerHeight * 1, paddingLeft: '10px' }} >
            <ListRTickets
              contact={contact}
              setOpenListTicket={setDrawerOpenTicket}
            />
          </div>
        </MainContainer>

      </Drawer>
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>

          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} handleDrawerCloseTicket={handleDrawerCloseTicket} handleScanMessage={handleDrawerCloseMessageList} />
          </div>

        </TicketHeader>

        <div className={classes.alert}>
          <Collapse in={open}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {messageAlert}
            </Alert>
          </Collapse>
        </div>

        <ReplyMessageProvider>
          <MessagesList
            ticketId={ticketId}
            isGroup={ticket.isGroup}
          ></MessagesList>
          <MessageInput ticketStatus={ticket.status} ticket={ticketId} />
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
      />

      {drawerMessageListOpen && (<MessagesListDrawer
        openDrawer={drawerMessageListOpen}
        closeDrawerHandle={handleDrawerCloseMessageList}
        contactId={contact.id}
        isGroup={ticket.isGroup}
      />)}
    </div>
  );
};

export default Ticket;
