import React, { useState, useEffect } from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TablePagination,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));


const LogModal = ({ open, onClose }) => {

	const classes = useStyles();

	const [logList, setLogList] = useState([]);
	const [logCount, setLogCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/whatsappsession/log", {
						params: { pageNumber },
					});

					setLogList(data.logs);
					setLogCount(data.count);
				} catch (err) {
					toastError(err);
				}
			};
			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [pageNumber]);

	const handleClose = () => {
		onClose();
	};

	const handlePageChange = (event, newPage) => {
		setPageNumber(newPage);
	};


	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					Visualizar Logs
				</DialogTitle>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								ID
							</TableCell>
							<TableCell>
								Data
							</TableCell>
							<TableCell>
								Titulo Log
							</TableCell>
							<TableCell>
								Descrição
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{logList?.length > 0 && (logList.map((log) => (
							<TableRow key={log.id}>
								<TableCell>
									{log.id}
								</TableCell>
								<TableCell style={{ fontSize: "10px" }} align="center">{format(parseISO(log.createdAt), "dd/MM/yy HH:mm:ss")}</TableCell>
								<TableCell>
									{log.title}
								</TableCell>
								<TableCell>
									{log.description}
								</TableCell>
							</TableRow>
						)))}
					</TableBody>
				</Table>


				<DialogActions>
					<TablePagination
						rowsPerPageOptions={[20]}
						component="div"
						onPageChange={handlePageChange}
						page={!logCount || logCount <= 0 ? 0 : pageNumber}
						count={logCount}
						rowsPerPage={20}
					/>
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
					>
						Fechar
					</Button>
				</DialogActions>

			</Dialog>
		</div>
	);
};

export default LogModal;
