import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const NetworkInformation = () => {

    const [time, setTime] = useState(1000);
    const [backgroundColor, setBackgroundCollor] = useState('success');
    const [messageConnection, setMessageConnection] = useState('Connected');
    const [open, setOpen] = React.useState(false);
    const isMountedComponent = useRef(true);

    useEffect(() => {

        handleConnectionChange();
        window.addEventListener("online", handleConnectionChange);
        window.addEventListener("offline", handleConnectionChange);

        return () => { isMountedComponent.current = false; };
    }, []);

    const handleConnectionChange = () => {

        console.log('handleConnectionChange');
        const webPing = setInterval(() => {
            fetch("//google.com", {
                mode: "no-cors",
                signal: AbortSignal.timeout(2000)
            })
                .then(() => {
                    if (isMountedComponent.current) { 

                        console.log('success');
                        setOpen(true);
                        setMessageConnection('Internet Online');
                        setBackgroundCollor("success");
                        setTime(3000);
                        clearInterval(webPing);
                    }else{
                        clearInterval(webPing);
                    }

                })
                .catch(() => {
                    if (isMountedComponent.current) { 
                        console.log('error');
                        setOpen(true);
                        setMessageConnection('Internet Offline');
                        setBackgroundCollor("error");
                        setTime(5000);
                    }else{
                        clearInterval(webPing);
                    }

                });
        }, 10000);


        return true;
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };



    return (

        <Snackbar open={open} autoHideDuration={time} onClose={handleClose}>
            <Alert onClose={handleClose} severity={backgroundColor}>
                {messageConnection}
            </Alert>
        </Snackbar>

    );
}

export default NetworkInformation;

