import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
}));

const MessageEditSchema = Yup.object().shape({
  message: Yup.string()
    .min(1, "Too Short!")
    .max(30000, "Too Long!")
    .required("Required"),
});

const MessageEditModal = ({
  open,
  onClose,
  message
}) => {

  
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const [messageEdit, setMessageEdit] = useState({});


  useEffect(() => {

    setMessageEdit({message: message?.body})
    return () => {
      isMounted.current = false;
    };

  }, [message]);



  const handleClose = () => {
    onClose(false);
  };

  const handleEditMessage = async (values) => {
   
    try {

      let msn = {
        id: message?.id,
        ticketId: message?.ticketId,
        body: values.message,
        isInternal: message.isInternal
      };

      if(!msn.id){
        msn.body = `*${user?.name}:*\n${msn?.body.trim()}`;
      }

      api.post(`/messages/edit`, msn, { timeout: 5000 })
        .then((sucess)=>{
          toast.success('Editado com Sucesso!');
          handleClose();
        })
        .catch(function (error) {
          console.log('api.post edit Error', error);
          toastError("Mensagem não foi alterada!");
        });

      
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-edit">
         { message?.id ? "Editar Mensagem" : "Enviar Mensagem"}
        </DialogTitle>
        <Formik
          initialValues={messageEdit}
          enableReinitialize={true}
          validationSchema={MessageEditSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleEditMessage(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label="Nova Mensagem"
                    name="message"
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    minRows={5}
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  { message?.id ? "Editar" : "Enviar"}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default MessageEditModal;
