import React, { useContext, useState, useEffect } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CallIcon from '@material-ui/icons/Call';
import Typography from '@material-ui/core/Typography';

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import copy from "copy-to-clipboard";
import MoreMenuItem from './MoreMenuItem'
import MessageEditModal from "../MessageEditModal"
import DescriptionIcon from '@material-ui/icons/Description';

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl, handleDrawerCloseTicket, handleScanMessage }) => {

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const { user } = useContext(AuthContext);
	const [messageInternalOpen, setMessageInternalOpen] = useState(false);

	useEffect(() => {

		document.addEventListener('keydown', keydownHandler);

		return () => {
			document.removeEventListener('keydown', keydownHandler);
		}
		// eslint-disable-next-line
	}, []);

	const keydownHandler = (e) => {
		if (e.keyCode === 90 && e.ctrlKey && e.shiftKey) {
			handleOpenTransferModal()
		}
	};
	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
		return [];
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleMessageScan = e => {
		handleScanMessage();
		handleClose();
	};

	const handleMessageInternal = () => {
		setMessageInternalOpen(true);
		handleClose();
	};

	const handleCall = () => {

		if (ticket?.contact?.number) {
			//https://sfu.mirotalk.com/join?room=test&password=0&name=mirotalksfu&audio=0&video=0&screen=0&notify=0
			let ms = "https://sfu.mirotalk.com/join?room=" + ticket?.contact?.number + "&password=0&name=Cliente&audio=1&video=0&screen=0&notify=0";
			copy(ms);

			handleClose();
			alert(`Aperte CTRL + V na caixa de mensagem para enviar o link ao Cliente`);

			window.open("https://sfu.mirotalk.com/join?room=" + ticket?.contact?.number + "&password=0&name=" + user.name + "&audio=1&video=0&screen=0&notify=0",
				'_blank');

		}
	};

	const handleStatusVerify = (value) => {

		try {
			api.put(`/tickets/${ticket.id}`, {
				fromMe: value
			});
			handleClose();
			console.log('value', value);
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenTicket = () => {
		handleClose();
		handleDrawerCloseTicket();
	}

	const handleCloseTransferTicketModal = () => {
		setTransferTicketModalOpen(false);

	};

	return (
		<>
			<MessageEditModal
				open={messageInternalOpen}
				onClose={setMessageInternalOpen}
				message={{ ticketId: ticket.id, isInternal: true }}
				aria-labelledby="form-dialog-title"
			></MessageEditModal>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}

			>
				<MenuItem onClick={handleOpenTransferModal}>
					{"Transferir (Ctrl+Shift+Z)"}
				</MenuItem>
				<Can
					role={user.profile}
					perform="ticket-options:deleteTicket"
					yes={() => (
						<MenuItem onClick={handleOpenConfirmationModal}>
							{i18n.t("ticketOptionsMenu.delete")}
						</MenuItem>
					)}
				/>
				<MenuItem onClick={handleCall}>
					
						<CallIcon fontSize="small" />
						<Typography variant="inherit">  Call</Typography>
		
				</MenuItem>

				<MenuItem onClick={handleOpenTicket}>
				<ListItemIcon>
						<DescriptionIcon fontSize="small" color="primary" />
						<Typography variant="inherit" color="primary"> Registrar Ticket</Typography>
					</ListItemIcon>
					
				</MenuItem>
				<MenuItem onClick={handleMessageScan}>
					Search Message
				</MenuItem>
				<MenuItem onClick={handleMessageInternal}>
					<Typography variant="inherit" color="primary">  Mensagem Interna</Typography>

				</MenuItem>

				<MoreMenuItem label="Status" id="menu-appbar">
					<MenuItem onClick={() => { handleStatusVerify(2) }}>Em Atendimento</MenuItem>
					<MenuItem onClick={() => { handleStatusVerify(3) }}>Em Chamada</MenuItem>
					<MenuItem onClick={() => { handleStatusVerify(4) }}>Aguardando Retorno CLiente</MenuItem>

				</MoreMenuItem>



			</Menu>
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${ticket.id
					} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${ticket.contact.name
					}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			{ticket?.id && (<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
				ticketWhatsappId={ticket.whatsappId}
			/>)}
		</>
	);
};

export default TicketOptionsMenu;
