import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import PropTypes from 'prop-types';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
	Tabs,
	Tab,
	Switch,
	Box,
	FormControlLabel,
	Tooltip
} from '@material-ui/core';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					{children}
				</Box>
			)}
		</div>
	);
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	code: Yup.string().nullable(true),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
	isPlantao: Yup.boolean(),
	telefone: Yup.string().matches(/^55\d{10,11}$/, 'Formato incorreto').when("isPlantao", {
		is: true,
		then: Yup.string().required("Must enter Telefone")
	}).nullable(true),
	segunda: Yup.string().matches(/^([0-2][0-9]:[0-5][0-9]-[0-2][0-9]:[0-5][0-9];){1,2}$/, 'Formato incorreto').when("isPlantao", {
		is: true,
		then: Yup.string().required("Must enter")
	}).nullable(true),
	terca: Yup.string().matches(/^([0-2][0-9]:[0-5][0-9]-[0-2][0-9]:[0-5][0-9];){1,2}$/, 'Formato incorreto').when("isPlantao", {
		is: true,
		then: Yup.string().required("Must enter")
	}).nullable(true),
	quarta: Yup.string().matches(/^([0-2][0-9]:[0-5][0-9]-[0-2][0-9]:[0-5][0-9];){1,2}$/, 'Formato incorreto').when("isPlantao", {
		is: true,
		then: Yup.string().required("Must enter")
	}).nullable(true),
	quinta: Yup.string().matches(/^([0-2][0-9]:[0-5][0-9]-[0-2][0-9]:[0-5][0-9];){1,2}$/, 'Formato incorreto').when("isPlantao", {
		is: true,
		then: Yup.string().required("Must enter")
	}).nullable(true),
	sexta: Yup.string().matches(/^([0-2][0-9]:[0-5][0-9]-[0-2][0-9]:[0-5][0-9];){1,2}$/, 'Formato incorreto').when("isPlantao", {
		is: true,
		then: Yup.string().required("Must enter")
	}).nullable(true),
	sabado: Yup.string().matches(/^([0-2][0-9]:[0-5][0-9]-[0-2][0-9]:[0-5][0-9];){1,2}$/, 'Formato incorreto').when("isPlantao", {
		is: true,
		then: Yup.string().required("Must enter")
	}).nullable(true),
	domingo: Yup.string().matches(/^([0-2][0-9]:[0-5][0-9]-[0-2][0-9]:[0-5][0-9];){1,2}$/, 'Formato incorreto').when("isPlantao", {
		is: true,
		then: Yup.string().required("Must enter")
	}).nullable(true),
});

const UserModal = ({ open, onClose, userId }) => {

	const classes = useStyles();
	const [valueTab, setValueTab] = useState(0);

	const semanaArray = ['segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado', 'domingo'];

	const initialState = {
		name: "",
		email: "",
		password: "",
		active: true,
		profile: "user",
		isPlantao: false,
		msgGrupo: false,
		telefone: "",
		segunda: "06:00-08:00;18:00-22:00;", terca: "06:00-08:00;18:00-22:00;",
		quarta: "06:00-08:00;18:00-22:00;", quinta: "06:00-08:00;18:00-22:00;", sexta: "06:00-08:00;18:00-22:00;",
		sabado: "06:00-22:00;", domingo: "06:00-22:00;",
		mgsIniciaPlantao: "",
		mgsFinalizaPlantao: ""
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const [whatsappId, setWhatsappId] = useState(false);
	const { loading, whatsApps } = useWhatsApps();

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);

				//console.log('data', data);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (err) {
				toastError(err);
			}
		};

		setValueTab(0);
		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		const userData = { ...values, whatsappId, queueIds: selectedQueueIds };
		try {

			//console.log('userData:', userData);
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};



	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<Tabs value={valueTab}
								onChange={(e, value) => setValueTab(value)}
								centered>
								<Tab label="Principal">
								</Tab>
								<Tab label="Plantao">
								</Tab>
							</Tabs>
							{/*ABA PRINCIPAL USUARIO*/}
							<TabPanel value={valueTab} index={0}>
								<DialogContent dividers>
									<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.name")}
											autoFocus
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
											fullWidth
										/>

										<Field
											as={TextField}
											name="password"
											variant="outlined"
											margin="dense"
											label={i18n.t("userModal.form.password")}
											error={touched.password && Boolean(errors.password)}
											helperText={touched.password && errors.password}
											type={showPassword ? 'text' : 'password'}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={() => setShowPassword((e) => !e)}
														>
															{showPassword ? <VisibilityOff color="secondary" /> : <Visibility color="secondary" />}
														</IconButton>
													</InputAdornment>
												)
											}}
											fullWidth
										/>
									</div>
									<div className={classes.multFieldLine}>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.email")}
											name="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
										<FormControl
											variant="outlined"
											className={classes.formControl}
											margin="dense"
										>
											<Can
												role={loggedInUser.profile}
												perform="user-modal:editProfile"
												yes={() => (
													<>
														<InputLabel id="profile-selection-input-label">
															{i18n.t("userModal.form.profile")}
														</InputLabel>

														<Field
															as={Select}
															label={i18n.t("userModal.form.profile")}
															name="profile"
															labelId="profile-selection-label"
															id="profile-selection"
															required
														>
															<MenuItem value="admin">Admin</MenuItem>
															<MenuItem value="user">User</MenuItem>
														</Field>
													</>
												)}
											/>
										</FormControl>
									</div>
									<Can
										role={loggedInUser.profile}
										perform="user-modal:editQueues"
										yes={() => (
											<QueueSelect
												selectedQueueIds={selectedQueueIds}
												onChange={values => setSelectedQueueIds(values)}
											/>
										)}
									/>


									<Can
										role={loggedInUser.profile}
										perform="user-modal:editQueues"
										yes={() => (!loading && (
											<>
												<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
													<InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
													<Field
														as={Select}
														value={whatsappId}
														onChange={(e) => setWhatsappId(e.target.value)}
														label={i18n.t("userModal.form.whatsapp")}

													>
														<MenuItem value={false}>&nbsp;</MenuItem>
														{whatsApps.map((whatsapp) => (
															<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
														))}
													</Field>
												</FormControl>

											</>

										)
										)}
									/>
									<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
										<Field
											as={TextField}
											label="Symbol Msg Not Sign"
											name="code"
											error={touched.code && Boolean(errors.code)}
											helperText={touched.code && errors.code}
											variant="outlined"
											margin="dense"
											fullWidth
											value={values.code ?? ''}
										/>
									</FormControl>
									<FormControlLabel
											control={
												<Field
													as={Switch}
													color="primary"
													name="active"
													checked={values.active}
												/>
											}
											label="Ativo"
										/>

								</DialogContent>
							</TabPanel>
							{/*ABA PLANTAO*/}
							<TabPanel value={valueTab} index={1}>

								{/*ATIVAR PLANTAO*/}
								<DialogContent dividers>
									<div className={classes.multFieldLine}>
										<Tooltip title="Ativando será encaminhado mensagem ao numero informado">
											<FormControlLabel
												control={
													<Field
														as={Switch}
														color="primary"
														name="isPlantao"
														checked={values.isPlantao}
													/>
												}
												label="Ativo"
											/>
										</Tooltip>
										<Field
											as={TextField}
											label="Telefone [Copie dos Contatos]"
											autoFocus
											name="telefone"
											error={touched.telefone && Boolean(errors.telefone)}
											helperText={touched.telefone && errors.telefone}
											variant="outlined"
											margin="dense"
											fullWidth
											disabled={!values.isPlantao}
											value={values.telefone ?? ''}

										/>
									</div>

								</DialogContent>
								{/** HORARIO DE PLANTAO */}
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Typography>Horários</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<DialogContent dividers>

											{
												semanaArray.map((atribute, index) => {

													return <div className={classes.multFieldLine} key={index}>
														<Field
															as={TextField}
															label={`Hrs ${atribute} Ex: 08:00-18:00;`}
															autoFocus
															name={atribute}
															error={touched[`${atribute}`] && Boolean(errors[`${atribute}`])}
															helperText={touched[`${atribute}`] && errors[`${atribute}`]}
															variant="outlined"
															margin="dense"
															fullWidth
															disabled={!values.isPlantao}
															value={values[`${atribute}`] ?? ''}
														/>
													</div>

												})
											}

										</DialogContent>
									</AccordionDetails>
								</Accordion>
								{/**MENSAGEM DE PLANTAO */}
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Typography>Mensagens</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<DialogContent dividers>
											<div className={classes.multFieldLine}>
												<FormControlLabel
													control={
														<Field
															as={Switch}
															color="primary"
															name="msgGrupo"
															checked={values.msgGrupo}
														/>
													}
													label="Enviar Mensagem Grupo"
												/>
											</div>
											<div className={classes.multFieldLine}>
												<Field
													as={TextField}
													label="Mensagem Durante Plantão"
													type="greetingMessage"
													multiline
													minRows={5}
													fullWidth
													name="mgsIniciaPlantao"
													error={
														touched.mgsIniciaPlantao && Boolean(errors.mgsIniciaPlantao)
													}
													helperText={
														touched.mgsIniciaPlantao && errors.mgsIniciaPlantao
													}
													variant="outlined"
													margin="dense"
													value={values.mgsIniciaPlantao ?? ''}
												/>
											</div>
											<div className={classes.multFieldLine}>
												<Field
													as={TextField}
													label="Mensagem Finalizou Plantão"
													type="greetingMessage"
													multiline
													minRows={5}
													fullWidth
													name="mgsFinalizaPlantao"
													error={
														touched.mgsFinalizaPlantao && Boolean(errors.mgsFinalizaPlantao)
													}
													helperText={
														touched.mgsFinalizaPlantao && errors.mgsFinalizaPlantao
													}
													variant="outlined"
													margin="dense"
													value={values.mgsFinalizaPlantao ?? ''}
												/>
											</div>
										</DialogContent>
									</AccordionDetails>
								</Accordion>
							</TabPanel>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>

						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
