export const GetColor = (code) => {

    switch (code) {
        case 0:
            return '#FFFFFF';
        case 1:
            return '#B22222';
        case 2:
            return '#4682B4';
        case 3:
            return '#3CB371';
        case 4:
            return '#FFA500';
        case 5:
            return '#DAA520';
        case 6:
            return '#7B68EE';
        default:
            return '';
    }
};

