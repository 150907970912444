import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import openSocket from "../../services/socket-io";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import { Field } from "formik";
import { useParams } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";
import { GetColor } from "./ColorCategory"
import { Drawer } from "@material-ui/core";
import { Formik, Form } from "formik";
import HeaderTicket from "./HeaderTicket";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment-timezone';
import RegistrationTicket from "./index";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import Checkbox from '@material-ui/core/Checkbox';

const reducer = (state, action) => {

  if (action.type === "LOAD_RTICKETS") {
    const tickets = action.payload;

    const newTickets = [];

    tickets.forEach((ticket) => {
      const ticketsIndex = state.findIndex((u) => u.id === ticket.id);
      if (ticketsIndex !== -1) {
        state[ticketsIndex] = ticket;
      } else {
        newTickets.push(ticket);
      }
    });

    return [...state, ...newTickets];
  }

  if (action.type === "UPDATE_RTICKETS") {
    const tickets = action.payload;

    const ticketsIndex = state.findIndex((u) => u.id === tickets.id);

    if (ticketsIndex !== -1) {
      state[ticketsIndex] = tickets;
      return [...state];
    } else {
      return [tickets, ...state];
    }
  }

  if (action.type === "DELETE_RTICKET") {
    const ticketsId = action.payload;

    const ticketsIndex = state.findIndex((u) => u.id === ticketsId);
    if (ticketsIndex !== -1) {
      state.splice(ticketsIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  headerCell: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#5995b2"

  },

  rowsCell: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    fontWeight: "bold"
  }
}));

const ListRTickets = ({ contact, setOpenListTicket }) => {

  //console.log('contact', contact);

  const routeParams = useParams();

  const [ticketId, setTicketId] = useState({});
  const [drawerOpenTicket, setDrawerOpenTicket] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, dispatch] = useReducer(reducer, []);
  const [finalPageNumber, setFinalPageNumber] = useState(true);

  const [userCreateId, setUserCreateId] = useState('');
  const [userId, setUserId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [statusId, setStatusId] = useState('');
  const [priorityId, setPriorityId] = useState('');
  const [selectedContact, setSelectedContact] = useState([]);
  const [selectedDateInicial, setSelectedDateInicial] = useState(null);
  const [selectedDateFinal, setSelectedDateFinal] = useState(null);
  const [searchParam, setSearchParam] = useState(initial());

  const [sorting, setSorting] = useState({ key: 'RTicket.id', ascending: 'DESC' })

  const [iconStatus, setIconStatus] = useState('⭥')
  const [iconId, setIconId] = useState('↓')

  useEffect(() => {

    if (contact) {
      searchParam['selectedContact'] = [{id: contact.id}];
    }else{

      if(!searchParam.hasOwnProperty("statusId")){
        searchParam['statusId'] = 3;
      }
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {

    let isMounted = true;

    if (isMounted) {
      dispatch({ type: "RESET" });
      setPageNumber(1);
    }

    return () => {
      isMounted = false;
    }

  }, [searchParam]);

  useEffect(() => {

    let rticketId = routeParams?.rticketId;

    if (!rticketId) {
      rticketId = '';
    }

    let isMounted = true;

    //console.log('searchParam', searchParam);
    //localStorage.setItem("initialSearchParam", JSON.stringify(searchParam));

    const delayDebounceFn = setTimeout(() => {
      setLoading(true);

      api.get("/rtickets/" + rticketId, {
        params: { searchParam, pageNumber, sorting },
      }).then((resp) => {

        if (isMounted) {

          if (resp?.data?.rtickets?.length > 0) {
            dispatch({ type: "LOAD_RTICKETS", payload: resp.data.rtickets });
            setHasMore(resp.data.hasMore);
          } else if (resp?.data?.title) {
            dispatch({ type: "LOAD_RTICKETS", payload: [resp.data] });
            setHasMore(false);
          }
          setLoading(false);
        }

      }).catch((err) => {

        toastError(err);
      });

    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
      isMounted = false;
    }
  }, [searchParam, pageNumber, contact, sorting, routeParams]);

  function initial() {


    return {};

  };

  useEffect(() => {
    const socket = openSocket();

    socket.on("rticket", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_RTICKETS", payload: data.rticket });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_RTICKET", payload: +data.ticketId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenTicket = () => {

    setTicketId({});
    setDrawerOpenTicket(true);
  };

  const handleSearchParam = (params) => {

    //console.log(params);
    let dataInicial = '';
    let dataFinal = '';

    if (selectedDateInicial)
      dataInicial = moment(selectedDateInicial).format('YYYY-MM-DD');
    if (selectedDateFinal)
      dataFinal = moment(selectedDateFinal).format('YYYY-MM-DD');

    ///console.log(dataInicial, dataFinal);

    setSearchParam({ ...params, userId, userCreateId, categoryId, statusId, priorityId, selectedContact, dataInicial, dataFinal });


  };

  const handleCleanFilter = () => {
    setSearchParam({});
    setUserCreateId('');
    setUserId('');
    setCategoryId('');
    setStatusId('');
    setPriorityId('');
    setSelectedContact([]);
    setSelectedDateInicial(null);
    setSelectedDateFinal(null);

    setOpen(false)

  };

  const handleEditTicket = (ticket) => {

    setTicketId(ticket);
    setDrawerOpenTicket(true);
  };

  const handleOpenAtendimento = (contactId) => {


    if (!contactId) return;

    setLoading(true);

    api.post("/tickets", {
      contactId: contactId
    }).then((ticket) => {

      history.push(`/tickets/${ticket.data.id}`);
      setLoading(false);

    }).catch((err) => {

      toastError(err);
      setLoading(false);
    });

  };


  const handleDeleteTicket = () => {

    api.delete(`/rtickets/${ticketId.id}`).then((response) => {

      toast.success(i18n.t("users.toasts.deleted"));

      setSearchParam("");
      setPageNumber(1);
      setTicketId({});

    }).catch((err) => {
      toastError(err);
      console.log(err);
    });

  };

  const handleOpenConfirmationModal = ticket => {
    setConfirmationOpen(true);
    setTicketId(ticket);
  };

  const loadMore = () => {

    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {

    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollTop !== 0) {

      if (scrollHeight - (scrollTop + 50) < clientHeight) {

        if (finalPageNumber) {
          setFinalPageNumber(false);
          loadMore();
        }

      } else {
        setFinalPageNumber(true);
      }
    }

  };

  const getListFilter = () => (

    <div style={{ width: 300, paddingLeft: '10px' }} >

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
        <Title>FILTRO (AND)</Title>
        <IconButton
          onClick={() => setOpen(false)}
        >
          <CloseIcon color="secondary" />
        </IconButton>
      </Box>
      <Formik
        initialValues={initial() || { title: '', subtitle: '', time: '', body: '' }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSearchParam(values);
            setSubmitting(false);
            setOpen(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting
          /* and other goodies */
        }) => (
          <Form>

            <HeaderTicket
              values={values}
              touched={touched}
              errors={errors}

              statusId={statusId}
              setStatusId={setStatusId}

              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}

              priorityId={priorityId}
              setPriorityId={setPriorityId}

              userCreateId={userCreateId}
              setUserCreateId={setUserCreateId}

              userId={userId}
              setUserId={setUserId}

              categoryId={categoryId}
              setCategoryId={setCategoryId}


            />

            <Box sx={{ display: 'flex', flexWrap: 'wrap', flex: 12 }}>
              <Field
                as={TextField}
                label="Corpo da Mensagem"
                name="body"
                variant="outlined"
                margin="dense"
                style={{ minWidth: 200, flex: 4, marginRight: 10 }}
                value={values.body}
              />
            </Box>

            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                label="Date Inicial Criado"
                value={selectedDateInicial}
                maxDate={selectedDateFinal}
                onChange={(date) => { setSelectedDateInicial(date) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}

                style={{ display: 'flex', flexWrap: 'wrap', flex: 12, marginRight: '10px', marginTop: '10px' }}
              />
              <KeyboardDatePicker
                disableToolbar
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="dense"
                id="date-picker-inline"
                label="Date Final Criado"
                value={selectedDateFinal}
                minDate={selectedDateInicial}
                onChange={(date) => { setSelectedDateFinal(date) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}

                style={{ display: 'flex', flexWrap: 'wrap', flex: 12, marginRight: '10px', marginTop: '10px' }}
              />
            </MuiPickersUtilsProvider>


            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px', marginTop: '10px' }}>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={handleCleanFilter}
              >LIMPAR FILTRO</Button>
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                type="submit"
                disabled={isSubmitting}
              >PESQUISAR</Button>
            </Box>

          </Form>
        )}
      </Formik>
    </div>

  );

  const getStringColor = (string = '', saturation = 100, lightness = 30) => {

    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }

    //console.log(`hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`);

    return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
  };

  function applySorting(key) {

    dispatch({ type: "RESET" });
    setPageNumber(1);

    if (sorting.ascending === 'DESC') {
      setSorting({ key: key, ascending: 'ASC' });

      if (key === 'RTicket.id') {
        setIconId('⭡');
        setIconStatus('⭥');
      } else {
        setIconId('⭥');
        setIconStatus('⭡');
      }
    } else {
      setSorting({ key: key, ascending: 'DESC' });
      if (key === 'RTicket.id') {
        setIconId('⭣');
        setIconStatus('⭥');
      } else {
        setIconId('⭥');
        setIconStatus('⭣');
      }
    }

    console.log(key, sorting);

  }

  return (
    <div >

      <Drawer
        open={drawerOpenTicket}
        anchor={"right"}
        onClose={() => setDrawerOpenTicket(false)}
      >

        <div style={{ maxWidth: window.innerWidth * 0.8, maxHeight: window.innerHeight * 1, paddingLeft: '10px' }} >
          <RegistrationTicket
            ticket={ticketId}
            contact={contact}
            onCloseTicket={setDrawerOpenTicket}
          />
        </div>


      </Drawer>

      <ConfirmationModal
        title={`Deseja Realmente Excluir Ticket ${ticketId.id}?`}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteTicket}
      >
        Não Será possível recuperar
      </ConfirmationModal>
      <MainHeader>
        <Drawer open={open} anchor={"right"} onClose={() => setOpen(false)}>
          {getListFilter()}
        </Drawer>
        <Title>Lista de Tickets</Title>
        <MainHeaderButtonsWrapper>

          {!contact && (<IconButton onClick={() => setOpen(true)}>
            {initial() && (<InputLabel>Filtro já preenchido</InputLabel>)}
            <SearchIcon color="secondary" />
          </IconButton>)}

          {contact && (<Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => setOpenListTicket(false)}
          >VOLTAR</Button>)}
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenTicket}
          >
            Novo
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <div style={{ maxWidth: window.innerWidth * 0.95, height: window.innerHeight * 0.8, paddingLeft: '10px' }}>
        <MainContainer>

          <Paper
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            {tickets.length === 0 && !loading ? (
              <div className={classes.noTicketsDiv}>
                <span className={classes.noTicketsTitle}>
                  {i18n.t("ticketsList.noTicketsTitle")}
                </span>
                <p className={classes.noTicketsText}>
                  Nenhum Ticket encontrado com esse status ou termo pesquisado
                </p>
              </div>
            ) : (

              <Table
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center"
                      onClick={() => applySorting('RTicket.id')}
                    >
                      <IconButton
                        className={classes.headerCell}
                      >
                        ID{iconId}
                      </IconButton>

                    </TableCell>
                    <TableCell className={classes.headerCell} align="center">
                      Data Criação
                    </TableCell>
                    <TableCell className={classes.headerCell} align="center">Criado Por</TableCell>
                    <TableCell className={classes.headerCell} align="center">Titulo</TableCell>
                    <TableCell
                      align="center"
                      onClick={() => {
                        applySorting('status.title')
                      }}
                    >
                      <IconButton
                        style={{
                          backgroundColor: '#4682B4',
                          color: '#ffff'
                        }}
                        className={classes.headerCell}

                      >
                        Status {iconStatus}
                      </IconButton>

                    </TableCell>
                    <TableCell className={classes.headerCell} align="center">
                      Cliente
                    </TableCell>
                    <TableCell className={classes.headerCell} align="center">
                      Prioridade
                    </TableCell>

                    <TableCell className={classes.headerCell} align="center">
                      Executado Por
                    </TableCell>
                    <TableCell className={classes.headerCell} align="center">
                      Bug
                    </TableCell>
                    <TableCell className={classes.headerCell} align="center">
                      {i18n.t("users.table.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {tickets.map((ticket) => (
                      <TableRow key={ticket.id}>
                        <TableCell style={{ fontSize: "10px" }} align="center">{ticket.id}</TableCell>
                        <TableCell style={{ fontSize: "10px" }} align="center">{format(parseISO(ticket.createdAt), "dd/MM/yy HH:mm")}</TableCell>
                        <TableCell
                          style={{
                            fontSize: "12px",
                            fontWeight: 'bold',
                            color: getStringColor(ticket?.userCreate?.name),
                          }}
                          align="center"
                        >{ticket?.userCreate?.name}
                        </TableCell>
                        <TableCell className={classes.rowsCell} align="center">{ticket.title}</TableCell>
                        <TableCell align="center"
                          style={{
                            color: GetColor(ticket.status?.color),
                            fontWeight: 'bold',
                            textTransform: 'uppercase'
                          }}
                        >{ticket.status?.title}</TableCell>

                        <TableCell align="center">
                          {
                            ticket.members.map((item, index) => (
                              <Chip
                                style={{ marginBottom: "5px" }}
                                size="small"
                                key={index}
                                label={
                                  <IconButton
                                    style={{ fontSize: "11px", color: "#000" }}
                                    onClick={() => handleOpenAtendimento(item.id)}
                                  >
                                    {item.name}
                                    <br />{item.number}
                                  </IconButton>
                                }
                                deleteIcon={<DoneIcon />}
                              />
                            ))
                          }
                        </TableCell>
                        <TableCell className={classes.rowsCell} align="center"
                          style={{
                            color: GetColor(ticket.priority?.color),
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            fontSize: "11px"
                          }}
                        >{ticket.priority?.title}</TableCell>

                        <TableCell
                          style={{
                            fontSize: "10px",
                            color: getStringColor(ticket?.userExecute?.name),
                          }}
                          align="center"
                        >{ticket?.userExecute?.name}</TableCell>
                        <TableCell style={{ fontSize: "10px" }} align="center">
                          <Checkbox
                            style={{ color: '#5995b2' }}
                            disabled
                            checked={ticket.isBug}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </TableCell>
                        <TableCell className={classes.rowsCell} align="center">
                          <IconButton
                            size="small"
                            onClick={() => handleEditTicket(ticket)}
                          >
                            <EditIcon color="secondary" />
                          </IconButton>


                          <IconButton
                            size="small"
                            onClick={() => handleOpenConfirmationModal(ticket)}
                          >
                            <DeleteOutlineIcon color="secondary" />
                          </IconButton>


                        </TableCell>
                      </TableRow>
                    ))}
                    {loading && <TableRowSkeleton columns={4} />}
                  </>
                </TableBody>
              </Table>

            )}
          </Paper>
        </MainContainer>

      </div>
    </div>
  );
};

export default ListRTickets;
